import { PermissionsService } from './../../../shared/services/permissions.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from 'src/app/shared/services/users.service';
declare var $: any;

@Component({
  selector: 'app-all-users',
  templateUrl: './all-users.component.html',
  styleUrls: ['./all-users.component.scss']
})
export class AllUsersComponent implements OnInit {

  users: any;
  user: any;
  loader: boolean;
  editForm: FormGroup;
  loading: boolean = true;
  roles: any;

  config: any;
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  totalItems = 0;
  showin = 0;

  constructor(
    private toast: ToastrService,
    private userService: UsersService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    public permService: PermissionsService
  ) {
    if(!this.permService.hasAccessAuthority('VIEW_USER')){
      toast.warning('Access Denied');
      router.navigateByUrl('landing-page');
    }
   }

  ngOnInit(): void {
    this.getUsers();

    this.editForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      roleId: ['', Validators.required],
      username: ['', Validators.required]
    });

    this.getRoles();
  }

  getRoles(){
    this.userService.getAllRoles()
    .subscribe(
      data => {
        // console.log(data);
        this.roles = data;
      },
      err => {
        console.log(err);
        this.toast.error('An Error Occured. Please Try Again.!!');
      }
    )
  }

  handlePageChange(event) {
    this.page = event;
    this.getUsers();
  }

  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getUsers();
  }

  getUsers(){
    this.userService.getPaginatedUsers(this.page - 1, this.pageSize)
    .subscribe(
      data => {
        // console.log(data);
        this.config = {
          itemsPerPage: this.pageSize,
          currentPage: data.pageable.pageNumber + 1,
          totalItems: data.totalElements,
          totalPages: data.totalPages
        };   

        if(this.page * this.pageSize > data.totalElements){
          this.showin = data.totalElements;
        }
        else{
          this.showin = this.page * this.pageSize;
        }

        this.users = data.content;
        this.loading = false;
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Users: ' + err.status);
        }
        this.loading = false;
      }
    )
  }

  activateUser(id, ele){
    // alert(id);
    $(ele.target).html('<i class="mdi mdi-autorenew mdi-spin"></i>');

    this.userService.activateUser(id)
    .subscribe(
      data => {
        // console.log(data);
        this.toast.success("User Activated.");
        // window.location.reload();
        $(ele.target).html('<i class="mdi mdi-check"></i>');
        this.getUsers();
      },
      error => {
        console.log(error);
        this.toast.error("Oops!! Something Happened.");
        $(ele.target).html('<i class="mdi mdi-window-close"></i>');
      } 
    );

  }

  deactivateUser(id, ele){
    // alert(id);
    $(ele.target).html('<i class="mdi mdi-autorenew mdi-spin"></i>');

    this.userService.deactivateUser(id)
    .subscribe(
      data => {
        // console.log(data);
        this.toast.success("User Deactivated.");
        // window.location.reload();
        $(ele.target).html('<i class="mdi mdi-check"></i>');
        this.getUsers();
      },
      error => {
        console.log(error);
        this.toast.error("Oops!! Something Happened.");
        $(ele.target).html('<i class="mdi mdi-window-close"></i>');
      }
    );
  }

  loadUser(ref){
    this.users.forEach(r => {
      if(r.id == ref){
        this.user = r;
      }
    });

    // console.log(this.user);

    if(this.user){
      this.editForm = this.fb.group({
        id: this.user.id,
        firstName: [this.user.firstname, Validators.required],
        lastName: [this.user.lastname, Validators.required],
        email: [this.user.email, Validators.required],
        roleId: [this.user.roles[0].id, Validators.required],
        username: [this.user.username, Validators.required]
      });

      // console.log(this.editForm.value);
    }
    else{
      this.toast.error('Failed to load user info.');
    }
    
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  updateUser(form: FormGroup){
    if(!this.validateEmail(form.value.email)){
      this.toast.warning('Enter valid email.');
      return false;
    }
    else{
      $('#emailErr').html('');
    }

    if(form.valid){
      this.loader = true;
      // console.log(form.value);

      this.userService.updateUser(form.value.id, form.value)
      .subscribe(
        response => {
          // console.log(response);
          this.loader = false;
          this.toast.success("User Updated");
          this.modalService.dismissAll();
          // this.loading = true;
          this.getUsers();
        },
        error => {
          this.loader = false;
          console.error(error);
          if(error.error.message){
            this.toast.error(error.error.message);
          }
          else{
            this.toast.error('An Error Occured. Please Try Again.!!!');
          }
        }
      );

    }
    else{
      this.toast.warning('Missing required Info.');
    }     
  }

  checkEmail(ele){
    // console.log(ele);

    var typed = ele.target.value;

    if(this.validateEmail(typed)){
      $(ele.target).removeClass('is-invalid');
      $(ele.target).addClass('is-valid');
    }
    else{
      $(ele.target).removeClass('is-valid');
      $(ele.target).addClass('is-invalid');
    }
  }

  openModal( modal ) {
    this.modalService.open( modal );
  }

  getName(name){
    if(name){
      return name.replace(/_/g, " ");
    }

    return '';
  }

}
