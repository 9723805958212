import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { environment } from './../../../../src/environments/environment';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  endpoint: string = environment.APIurl + '/akupay-oauth-service/api/v1';
  endpoint2: string = environment.APIurl + ':8202/api/v1';

  constructor(
    private http: HttpClient,
    private router: Router,
    private toasr: ToastrService,
    private storageService: StorageService
  ) { }

  generalSignIn(data) : Observable<any> {
    return this.http.post<any>(`${this.endpoint}/login`, data);
  }

  newSignIn(data) : Observable<any> {
    return this.http.post<any>(`${this.endpoint}/client-login`, data);
  }

  getToken() {
    return this.storageService.decrypt(localStorage.getItem('access_token'));
  }

  getUserDisplayName() {
    return this.storageService.decrypt(localStorage.getItem('display_name'));
  }

  getUserUsername() {
    return this.storageService.decrypt(localStorage.getItem('user_name'));
  }

  getUserType() {
    return this.storageService.decrypt(localStorage.getItem('user_type'));
  }

  get isLoggedIn(): boolean {
    // return true;

    let authToken = localStorage.getItem('access_token');
    return (authToken !== null) ? true : false;
  }

  doLogout() {

    let removeToken = localStorage.removeItem('access_token');
    let removeName = localStorage.removeItem('display_name');
    localStorage.removeItem('user_type');
    localStorage.removeItem('user_name');
    
    if (removeToken == null && removeName == null) {
      window.location.href = '/login';
    }
  }

}
