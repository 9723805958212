import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const baseUrl = environment.APIurl + '/akupay-user-manager/api/v1';
const baseUrl2 = environment.APIurl + '/akupay-user-manager';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private http: HttpClient
  ) { }

  getAllUsers(): Observable<any> {
    return this.http.get(baseUrl + '/users/0/1000');
  }

  getAllAdminUsers(): Observable<any> {
    return this.http.get(baseUrl + '/users/admin/0/1000');
  }

  getPaginatedUsers(page, size): Observable<any> {
    return this.http.get(baseUrl + '/users/' + page + '/' + size);
  }

  getPaginatedAdminUsers(page, size): Observable<any> {
    return this.http.get(baseUrl + '/users/admin/' + page + '/' + size);
  }

  getManipulatedUsers(page, size, query): Observable<any> {
    if(query){
      return this.http.get(baseUrl + '/users/admin/' + page + '/' + size + query);
    }

    return this.http.get(baseUrl + '/users/admin/' + page + '/' + size);
  }

  getById(id): Observable<any> {
    return this.http.get(`${baseUrl}/users/${id}`);
  }

  createUser(data): Observable<any> {
    return this.http.post(baseUrl + '/user/registration', data);
  }

  updateUser(id, data): Observable<any> {
    return this.http.put(`${baseUrl}/user/edit-user/${id}`, data);
  }

  activateUser(id): Observable<any> {
    return this.http.put(`${baseUrl}/user/activate/${id}`, null);
  }

  deactivateUser(id): Observable<any> {
    return this.http.put(`${baseUrl}/user/deactivate/${id}`, null);
  }  

  resetUser(email) : Observable<any>{
    var query = '?email=' + email;
    return this.http.post<any>(`${baseUrl}/user/resetPassword` + query, null);
  }

  resetUserPassword(data) : Observable<any>{
    return this.http.post( baseUrl + '/user/savePassword', data);
  }

  changeUserPassword(data) : Observable<any>{
    return this.http.post( baseUrl + '/user/change-password', data);
  }

  // users permissions
  getAllRoles(): Observable<any> {
    return this.http.get(baseUrl + '/user/roles');
  }

  getAssignedPermissions(id): Observable<any> {
    return this.http.get(baseUrl2 + '/user-permission/assigned/' + id);
  }

  getUnassignedPermissions(id): Observable<any> {
    return this.http.get(baseUrl2 + '/user-permission/un-assigned/' + id);
  }

  assignPermission(data): Observable<any> {
    return this.http.post(baseUrl2 + '/user-permission/assign-permissions', data);
  }

  unassignPermission(data): Observable<any> {
    return this.http.post(baseUrl2 + '/user-permission/un-assign-permissions', data);
  }

}
