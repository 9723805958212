import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AgentsService } from 'src/app/shared/services/agents.service';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
declare var $: any;

@Component({
  selector: 'app-all-agents',
  templateUrl: './all-agents.component.html',
  styleUrls: ['./all-agents.component.scss']
})
export class AllAgentsComponent implements OnInit {

  agents: any;
  loading: boolean = true;

  config: any;
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  totalItems = 0;
  showin = 0;

  constructor(
    private toast: ToastrService,
    private agentService: AgentsService,
    private router: Router,
    public permService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.getAgents();
  }

  handlePageChange(event) {
    this.page = event;
    this.getAgents();
  }

  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getAgents();
  }

  getAgents(){
    this.agentService.getPaginatedAgents(this.page - 1, this.pageSize)
    .subscribe(
      data => {
        // console.log(data);
        this.config = {
          itemsPerPage: this.pageSize,
          currentPage: data.pageable.pageNumber + 1,
          totalItems: data.totalElements,
          totalPages: data.totalPages
        };   

        if(this.page * this.pageSize > data.totalElements){
          this.showin = data.totalElements;
        }
        else{
          this.showin = this.page * this.pageSize;
        }
        this.agents = data.content;
        this.loading = false;
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Merchants: ' + err.status);
        }
        this.loading = false;
      }
    );
  }

  loadAgent(ref){
    if(ref){
      // console.log(ref);
      
      this.router.navigate(['/admin/merchants/info/' + ref]);
    }
  }

  updateAgent(ref){
    if(ref){
      this.router.navigate(['/admin/merchants/update/' + ref]);
    }
  }

  activateAgent(ref, ele) {

    $(ele.target).html('<i class="mdi mdi-autorenew mdi-spin"></i>');

    this.agentService.changeAgentStatus(ref, true)
      .subscribe(
        data => {
          // console.log(data);
          this.toast.success("Agent Activated.");
          // window.location.reload();
          $(ele.target).html('<i class="mdi mdi-check"></i>');
          this.loading = true;
          this.getAgents();
        },
        error => {
          console.log(error);
          if(error.status == 403){
            this.toast.error('You are not Authorised to perform operation.');
          }
          else{
            this.toast.error('An Error Occured. Please Try Again.!!!');
          }
          $(ele.target).html('<i class="mdi mdi-window-close"></i>');
        }
      );
  }

  deactivateAgent(ref, ele) {
    $(ele.target).html('<i class="mdi mdi-autorenew mdi-spin"></i>');

    this.agentService.changeAgentStatus(ref, false)
      .subscribe(
        data => {
          // console.log(data);
          this.toast.success("Agent De-activated.");
          // window.location.reload();
          $(ele.target).html('<i class="mdi mdi-check"></i>');
          this.loading = true;
          this.getAgents();
        },
        error => {
          console.log(error);
          if(error.status == 403){
            this.toast.error('You are not Authorised to perform operation.');
          }
          else{
            this.toast.error('An Error Occured. Please Try Again.!!!');
          }
          $(ele.target).html('<i class="mdi mdi-window-close"></i>');
        }
      );
  }

}
