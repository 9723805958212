import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from 'src/app/shared/services/users.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  loader: boolean;
  resetForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private toast: ToastrService,
    private userService: UsersService
  ) { }

  ngOnInit(): void {
    this.resetForm = this.fb.group({
      email: ['', Validators.required]
    });
  }

  

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  checkEmail(ele){
    // console.log(ele);

    var typed = ele.target.value;

    if(this.validateEmail(typed)){
      $(ele.target).removeClass('is-invalid');
      $(ele.target).addClass('is-valid');
    }
    else{
      $(ele.target).removeClass('is-valid');
      $(ele.target).addClass('is-invalid');
    }
  }

  resetUser(){

    // console.log(this.resetForm.value);

    if(this.resetForm.valid){
      this.loader = true;

      this.userService.resetUser(this.resetForm.value.email)
      .subscribe(
        res => {
          // console.log(res);

          let respo = res.message;

          if(respo == "User with that email does not exist"){
            this.toast.error(respo);
            this.loader = false;
          }
          else{
            this.toast.success(respo);
            this.loader = false;

            $('#onSuccess').html('Reset Request Initiated. Check Email.');

            this.resetForm.reset();
          } 

        },
        error => {
          console.log(error);
          this.loader = false;

          if(error.status == 0){
            this.toast.error("Oops!! No Network.");
            this.loader = false;
          }
          if(error.status == 500){
            this.toast.error("Oops!! Internal Server Error.");
            this.loader = false;
          }
          else{
            if(error.error.message != ""){
              let respo = error.error.message;
              this.toast.error(respo);
            }
            else{
              let respo = error.error;
              // console.log(respo);
              // alert(respo);
              this.toast.error(respo.error);
            }
          }
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }

  }

}
