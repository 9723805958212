import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { PermissionsService } from '../services/permissions.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public uiAccountingCollapsed = false;
  public uiAgentsCollapsed = false;
  public uiTransactionsCollapsed = false;
  public uiUsersCollapsed = false;
  public uiConfigsCollapsed = false;
  public uiSubscribersCollapsed = false;
  public uiDisbursementCollapsed = false;
  public uiLoansCollapsed = false;
  public uiAdjustmentsCollapsed = false;

  myName: string;
  myTitle: string;
  
  constructor(
    private authService: AuthService,
    public permService: PermissionsService
  ) { }

  ngOnInit() {

    if(window.location.pathname.includes('users')){
      this.uiUsersCollapsed = true;
    }
    else if(window.location.pathname.includes('agents')){
      this.uiAgentsCollapsed = true;
    }
    else if(window.location.pathname.includes('configs')){
      this.uiConfigsCollapsed = true;
    }
    else if(window.location.pathname.includes('subscribers')){
      this.uiSubscribersCollapsed = true;
    }
    else if(window.location.pathname.includes('disbursements')){
      this.uiDisbursementCollapsed = true;
    }
    else if(window.location.pathname.includes('accounting')){
      this.uiAccountingCollapsed = true;
    }
    else if(window.location.pathname.includes('transactions')){
      this.uiTransactionsCollapsed = true;
    }
    else if(window.location.pathname.includes('loans')){
      this.uiLoansCollapsed = true;
    }
    else if(window.location.pathname.includes('adjustments')){
      this.uiAdjustmentsCollapsed = true;
    }

    this.myName = this.authService.getUserDisplayName();
    this.myTitle = this.removeUnderscore(this.authService.getUserType()?.substring(5));

    const body = document.querySelector('body');

    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

  public removeUnderscore(str: string) {
    if(str){
      return str.replace(/_/g, " ");
    }
  }

}
