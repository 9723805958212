import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { ConfigsService } from "src/app/shared/services/configs.service";
import { PermissionsService } from "src/app/shared/services/permissions.service";

@Component({
  selector: "app-agent-velocities",
  templateUrl: "./agent-velocities.component.html",
  styleUrls: ["./agent-velocities.component.scss"],
})
export class AgentVelocitiesComponent implements OnInit {
  
  velocities: any;
  velocity: any;
  profiles: any;
  loading: boolean;
  loader: boolean;
  editLoader: boolean;
  addForm: FormGroup;
  editForm: FormGroup;

  constructor(
    private toast: ToastrService,
    private configService: ConfigsService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    public permService: PermissionsService,
    private router: Router
  ) {
    if(!this.permService.hasAccessAuthority('VIEW_AGENT_VELOCITY')){
      toast.warning('Access Denied');
      router.navigateByUrl('landing-page');
    }
   }

  ngOnInit(): void {
    this.getVelocities();
    this.getProfiles();

    this.addForm = this.fb.group({
      minWeeklyDebit: 0,
      maxWeeklyDebit: 0,
      minDailyDebit: 0,
      maxDailyDebit: 0,
      minMonthlyDebit: 0,
      maxMonthlyDebit: 0,
      minWeeklyCredit: 0,
      maxWeeklyCredit: 0,
      minDailyCredit: 0,
      maxDailyCredit: 0,
      minMonthlyCredit: 0,
      maxMonthlyCredit: 0,
      agentProfileId: 0,
    });

    this.editForm = this.fb.group({
      id: 0,
      minWeeklyDebit: 0,
      maxWeeklyDebit: 0,
      minDailyDebit: 0,
      maxDailyDebit: 0,
      minMonthlyDebit: 0,
      maxMonthlyDebit: 0,
      minWeeklyCredit: 0,
      maxWeeklyCredit: 0,
      minDailyCredit: 0,
      maxDailyCredit: 0,
      minMonthlyCredit: 0,
      maxMonthlyCredit: 0,
      agentProfileId: 0,
    });
  }

  getProfiles() {
    this.configService.getAgentProfiles().subscribe(
      (data) => {
        // console.log(data);
        this.profiles = data.content;
      },
      (err) => {
        console.log(err);
        if ((err.status == 0)) {
          this.toast.error("Failed to Connect.");
        } else {
          this.toast.error("Failed to get Profiles: " + err.status);
        }
      }
    );
  }

  getVelocities() {
    this.loading = true;

    this.configService.getAgentVelocities().subscribe(
      (data) => {
        // console.log(data);
        this.loading = false;
        this.velocities = data.content;
      },
      (err) => {
        console.log(err);
        if ((err.status == 0)) {
          this.toast.error("Failed to Connect.");
        } else {
          this.toast.error("Failed to get Velocities: " + err.status);
        }
      }
    );
  }

  addVelocity(form: FormGroup) {
    // console.log(form.value);

    if (form.valid) {
      this.loader = true;

      this.configService.createAgentVelocity(form.value).subscribe(
        (resp) => {
          // console.log(resp);
          this.toast.success("Merchant Velocity Added.");
          this.modalService.dismissAll();
          this.addForm.reset();
          this.loader = false;
          this.getVelocities();
        },
        (err) => {
          console.log(err);
          this.loader = false;
          if ((err.status == 0)) {
            this.toast.error("Failed to Connect.");
          } else {
            this.toast.error("An Error Occured. Try Again.!");
          }
        }
      );
    } else {
      this.toast.warning("Missing Required Info.");
    }
  }

  loadVelocity(ref, modal){
    if(ref){
      this.velocities.forEach(r => {
        if(r.id == ref){
          this.velocity = r;
        }
      });
    }

    if(this.velocity){
      this.editForm = this.fb.group({
        id: this.velocity.id,
        minWeeklyDebit: this.velocity.minWeeklyDebit,
        maxWeeklyDebit: this.velocity.maxWeeklyDebit,
        minDailyDebit: this.velocity.minDailyDebit,
        maxDailyDebit: this.velocity.maxDailyDebit,
        minMonthlyDebit: this.velocity.minMonthlyDebit,
        maxMonthlyDebit: this.velocity.maxMonthlyDebit,
        minWeeklyCredit: this.velocity.minWeeklyCredit,
        maxWeeklyCredit: this.velocity.maxWeeklyCredit,
        minDailyCredit: this.velocity.minDailyCredit,
        maxDailyCredit: this.velocity.maxDailyCredit,
        minMonthlyCredit: this.velocity.minMonthlyCredit,
        maxMonthlyCredit: this.velocity.maxMonthlyCredit,
        // agentProfileId: this.velocity.agentProfileId
      });

      this.openModal(modal);
    }
  }

  updateVelocity(form: FormGroup) {
    console.log(form.value);

    if (form.valid) {
      this.editLoader = true;

      this.configService.updateAgentVelocity(form.value).subscribe(
        (resp) => {
          // console.log(resp);
          this.toast.success("Merchant Velocity Updated.");
          this.modalService.dismissAll();
          this.addForm.reset();
          this.editLoader = false;
          this.getVelocities();
        },
        (err) => {
          console.log(err);
          this.editLoader = false;
          if ((err.status == 0)) {
            this.toast.error("Failed to Connect.");
          } else {
            this.toast.error("An Error Occured. Try Again.!");
          }
        }
      );
    } else {
      this.toast.warning("Missing Required Info.");
    }
  }

  openModal(modal) {
    this.modalService.open(modal);
  }
}
