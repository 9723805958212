import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  perms: any;

  constructor() { }

  hasAccessAuthority(val: string): boolean {

    // console.log(val);

    // get user permissions
    if(!this.perms){
      this.perms = JSON.parse(localStorage.getItem('authorities'));
    }

    
    if(!this.perms){
      return false;
    }

    // console.log(this.perms);

    return this.perms.includes(val);

  }

}
