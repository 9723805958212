import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FileService } from 'src/app/shared/services/file.service';
declare var $: any;

@Component({
  selector: 'app-batch-report',
  templateUrl: './batch-report.component.html',
  styleUrls: ['./batch-report.component.scss']
})
export class BatchReportComponent implements OnInit {

  batchNum: number;
  loading: boolean;
  report: any;

  config: any;
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  totalItems = 0;
  showin = 0;

  constructor(
    private toast: ToastrService,
    private fileService: FileService
  ) { }

  ngOnInit(): void {
    
  }

  handlePageChange(event) {
    this.page = event;
    this.getReport();
  }

  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getReport();
  }

  handleGet(){
    let val = $('#batchNum').val();

    if(val){
      this.batchNum = val;
      this.loading = true;
      this.getReport();
    }
    else{
      this.toast.warning('Enter Batch Number.');
    }
  }

  getReport(){
    this.fileService.getPaginatedBatchRegistrationReport(this.batchNum, this.page - 1, this.pageSize)
    .subscribe(
      data => {
        console.log(data);
        this.config = {
          itemsPerPage: this.pageSize,
          currentPage: data.pageable.pageNumber + 1,
          totalItems: data.totalElements,
          totalPages: data.totalPages
        };   

        if(this.page * this.pageSize > data.totalElements){
          this.showin = data.totalElements;
        }
        else{
          this.showin = this.page * this.pageSize;
        }
        this.loading = false;
        this.report = data.content;
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Report: ' + err.status);
        }
        this.loading = false;
      }
    )
  }

}
