import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AgentsRoutingModule } from './agents-routing.module';
import { NewAgentComponent } from './new-agent/new-agent.component';
import { AllAgentsComponent } from './all-agents/all-agents.component';
import { AgentInfoComponent } from './agent-info/agent-info.component';
import { UpdateAgentComponent } from './update-agent/update-agent.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { AgentTransactionsComponent } from './agent-transactions/agent-transactions.component';


@NgModule({
  declarations: [NewAgentComponent, AllAgentsComponent, AgentInfoComponent, UpdateAgentComponent, AgentTransactionsComponent],
  imports: [
    CommonModule,
    AgentsRoutingModule,
    ReactiveFormsModule,
    NgxPaginationModule
  ]
})
export class AgentsModule { }
