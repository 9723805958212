import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const baseUrl = environment.APIurl + '/akupay-audit-trail-service/api/v1/audits';
// const baseUrl = 'http://62.171.136.41:8762/api/v1' + '/audit-trail/audits';

@Injectable({
  providedIn: 'root'
})
export class AuditsService {

  constructor(
    private http: HttpClient
  ) { }

  getAllTrails(): Observable<any> {
    return this.http.get(baseUrl + '/all/0/1000');
  }

  getPaginatedTrails(page, size): Observable<any> {
    return this.http.get(baseUrl + '/all/' + page + '/' + size);
  }

  getManipulatedPaginatedTrails(page, size, param, search): Observable<any> {

    if(param && search){
      return this.http.get(baseUrl + '/date-range/' + page + '/' + size + '?' + search + '&' + param);
    }
    else if(param && !search){
      return this.http.get(baseUrl + '/date-range/' + page + '/' + size + '?' + param);
    }
    else if(!param && search){
      return this.http.get(baseUrl + '/all/' + page + '/' + size + '?' + search);
    }
    else{
      return this.http.get(baseUrl + '/all/' + page + '/' + size);
    }
    
  }

  exportAuditTrails(params, query): Observable<any> {
    if(query){
      return this.http.get(baseUrl + '/generate-audit-report?' + query + '&' + params, {responseType: 'blob'});
    }

    return this.http.get(baseUrl + '/generate-audit-report?' + params, {responseType: 'blob'});
  }

  // billers list report
  exportBillers(typ): Observable<any> {
    return this.http.get(baseUrl + '/generate-biller-report?reportFormat=' + typ, {responseType: 'blob'});
  }
  
  exportBillerTransactions(params, query): Observable<any> {
    if(query){
      return this.http.get(baseUrl + '/generate-biller-transactions-report?' + query + '&' + params, {responseType: 'blob'});
    }

    return this.http.get(baseUrl + '/generate-biller-transactions-report?' + params, {responseType: 'blob'});
  }

  // Subscribers reports
  exportSubscriberTransactions(params, typ): Observable<any> {
    return this.http.get(baseUrl + '/generate-subscriber-transactions-report?reportFormat=' + typ + '&' + params, {responseType: 'blob'});
  }

  exportSubscribers(typ): Observable<any> {
    return this.http.get(baseUrl + '/generate-subscribers-report?reportFormat=' + typ, {responseType: 'blob'});
  }

  // export reports
  exportEValueReport(params): Observable<any> {
    return this.http.get(baseUrl + '/generate-evalue-report?' + params, {responseType: 'blob'});
  }

  exportTrustAccountsReport(params): Observable<any> {
    return this.http.get(baseUrl + '/generate-snapshot-report?' + params, {responseType: 'blob'});
  }

  exportExceptionReport(params): Observable<any> {
    return this.http.get(baseUrl + '/generate-exception-report?' + params, {responseType: 'blob'});
  }

  exportFeesReport(params): Observable<any> {
    return this.http.get(baseUrl + '/generate-fees-report?' + params, {responseType: 'blob'});
  }

  exportTaxReport(params): Observable<any> {
    return this.http.get(baseUrl + '/generate-tax-report?' + params, {responseType: 'blob'});
  }

  exportP2PStatementReport(params): Observable<any> {
    return this.http.get(baseUrl + '/generate-p2p-statement-report?' + params, {responseType: 'blob'});
  }

  // merchants list report
  exportMerchants(typ): Observable<any> {
    return this.http.get(baseUrl + '/generate-merchant-report?reportFormat=' + typ, {responseType: 'blob'});
  }

  exportMerchantTransactions(params, typ): Observable<any> {
    // if(query){
    //   return this.http.get(baseUrl + '/generate-merchant-transactions-report?' + query + '&' + params, {responseType: 'blob'});
    // }

    // return this.http.get(baseUrl + '/generate-merchant-transactions-report?' + params, {responseType: 'blob'});

    return this.http.get(baseUrl + '/generate-merchant-transactions-report?reportFormat=' + typ + '&' + params, {responseType: 'blob'});
  }

  // transactions report
  exportTransactions(params, query): Observable<any> {
    if(query){
      return this.http.get(baseUrl + '/generate-transactions-report?' + query + '&' + params, {responseType: 'blob'});
    }

    return this.http.get(baseUrl + '/generate-transactions-report?' + params, {responseType: 'blob'});
  }

  // devices reports
  exportDeviceTransactions(params, query): Observable<any> {
    if(query){
      return this.http.get(baseUrl + '/generate-device-transactions-report?' + query + '&' + params, {responseType: 'blob'});
    }

    return this.http.get(baseUrl + '/generate-device-transactions-report?' + params, {responseType: 'blob'});
  }

  // operator reports
  exportOperatorTransactions(params, query): Observable<any> {
    if(query){
      return this.http.get(baseUrl + '/generate-operator-transactions-report?' + query + '&' + params, {responseType: 'blob'});
    }

    return this.http.get(baseUrl + '/generate-operator-transactions-report?' + params, {responseType: 'blob'});
  }

  // product reports
  exportProductTransactions(params, query): Observable<any> {
    if(query){
      return this.http.get(baseUrl + '/generate-product-transactions-report?' + query + '&' + params, {responseType: 'blob'});
    }

    return this.http.get(baseUrl + '/generate-product-transactions-report?' + params, {responseType: 'blob'});
  }

}
