import { PermissionsService } from './../../../shared/services/permissions.service';
import { HttpEventType, HttpResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import * as fileSaver from 'file-saver';
import { ToastrService } from "ngx-toastr";
import { AgentsService } from "src/app/shared/services/agents.service";
import { FileService } from "src/app/shared/services/file.service";
declare var $: any;

var slidePage: any;
var progressText: any;
var progressCheck: any;
var bullet: any;
let current = 1;

@Component({
  selector: "app-bulk-registration",
  templateUrl: "./bulk-registration.component.html",
  styleUrls: ["./bulk-registration.component.scss"],
})
export class BulkRegistrationComponent implements OnInit {

  loader: boolean;
  prog_file: number;
  agents: any;
  agent: any;
  agentId: number;
  profiles: any;
  profileId: number = 2;

  constructor(
    private toast: ToastrService,
    private fileService: FileService,
    private agentService: AgentsService,
    private router: Router,
    private permService: PermissionsService
  ) {
    if(!this.permService.hasAccessAuthority('BULK_REGISTRATION')){
      toast.warning('Access Denied');
      router.navigateByUrl('landing-page');
    }
   }

  ngOnInit(): void {
    slidePage = $(".slide-page");
    progressText = $(".step p");
    progressCheck = $(".step .check");
    bullet = $(".step .bullet");

    this.getAgents();
  }

  stepForward() {
    slidePage.css("marginLeft", "-25%");
    $(bullet[current - 1]).addClass("active");
    $(progressCheck[current - 1]).addClass("active");
    $(progressText[current - 1]).addClass("active");
    current += 1;
  }

  stepBack() {
    slidePage.css("marginLeft", "0%");
    $(bullet[current - 2]).removeClass("active");
    $(progressCheck[current - 2]).removeClass("active");
    $(progressText[current - 2]).removeClass("active");
    current -= 1;
  }

  getAgents(){
    this.agentService.getAllAgents()
    .subscribe(
      data => {
        // console.log(data.content);
        
        this.agents = data.content;
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Merchants: ' + err.status);
        }
      }
    )
  }

  showArea(sho, hid) {
    if (sho == 2) {
      if (!$("#file-name").val()) {
        this.toast.warning("Missing Required Info.");
        return false;
      }
    }

    if (sho == 3) {
      setTimeout(function () {
        // alert('hey');

        $("#loader").hide();
        $("#message").show();
      }, 3500);
    }

    if (hid) {
      $("#area" + hid).hide();
    }

    $("#area" + sho).show();

    if (sho > hid) {
      this.stepForward();
    } else {
      this.stepBack();
    }
  }

  uploadFile(event) {
    $("#fileSync").html('<i class="text-info mdi mdi-spin mdi-sync"></i>');
    var file = event.target.files[0];
    $("#file-name").val("test");

    this.fileService.uploadDocument(file).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this["prog_file"] = Math.round((100 * event.loaded) / event.total);
          // console.log(`Uploaded! ${idProgress}%`);
        } else if (event instanceof HttpResponse) {
          // console.log(event.body);
          var resp = event.body.fileName;
          // console.log(resp);

          $("#file-name").val(resp);
          $("#file-name").show();
          $("#upload-file").hide();
          $("#fileSync").html('<i class="mdi mdi-check text-success"></i>');
          this.toast.success("", "File Saved!!");
          $("#agentArea").show();
        }
      },
      (err) => {
        this.toast.error("Could not upload the file!!");
        $("#fileSync").html('<i class="mdi mdi-cancel text-danger"></i>');
      }
    );
  }

  initiateBulk() {
    if (!$("#file-name").val()) {
      this.toast.warning("Missing Required Info.");
      return false;
    }

    this.loader = true;

    this.fileService.initiateBulkReg($("#file-name").val(), this.profileId, this.agentId).subscribe(
      (resp) => {
        console.log(resp);
        this.loader = false;
        this.toast.success("Bulk Registration Successful");
        this.stepForward();
        this.showArea(3, 2);
      },
      (error) => {
        console.log(error);
        try {
          if (error.error.message) {
            this.toast.error(error.error.message);
          } else {
            this.toast.error("An Error Occured. Please Try Again.!!!");
          }
        } catch (err) {
          console.log(err);
          this.toast.error("An Error Occured. Please Try Again.!!!");
        }
        this.loader = false;
      }
    );
  }

  selectedAgent(ele){
    var sel = ele.target.value;

    // console.log(sel);
    this.agents.forEach(r => {
      if(r.businessName == sel){
        this.agent = r;
        this.agentId = r.id;
      }
    });

    // console.log(this.agent);    
    
  }

  downloadTemplate(){
    this.fileService.getTemplate('registration')
    .subscribe(
      data => {
        console.log(data);
        fileSaver.saveAs(data, 'registration.csv');
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Template: ' + err.status);
        }
      }
    )
  }
}
