import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const baseUrl = environment.APIurl + '/akupay-transaction-service/api/v1';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {

  constructor(
    private http: HttpClient
  ) { }

  getDashboardTransactionsByType(query): Observable<any> {
    return this.http.get(baseUrl + '/transactions/all/0/5' + query);
  }

  getTransactionById(ref): Observable<any> {
    return this.http.get(baseUrl + '/transactions/' + ref);
  }

  getManipulatedTransactions(query): Observable<any> {
    return this.http.get(baseUrl + '/transactions/date-range/' + query);
  }

  getAllDateRangeTransactions(): Observable<any> {
    return this.http.get(baseUrl + '/transactions/date-range/0/1000?startDate=2020-01-01&endDate=2021-12-31');
  }

  getAllPaginatedDateRangeTransactions(page, size, range): Observable<any> {
    return this.http.get(baseUrl + '/transactions/date-range/' + page + '/' + size + '?' + range);
  }

  getAgentPaginatedDateRangeTransactions(agent, page, size, range):Observable<any> {
    return this.http.get(baseUrl + '/transactions/agent/' + page + '/' + size + '?agentId=' + agent + '&' + range);
  }

  getOperatorPaginatedDateRangeTransactions(agent, page, size, range):Observable<any> {
    return this.http.get(baseUrl + '/transactions/operator/' + page + '/' + size + '?tellerId=' + agent + '&' + range);
  }

  getSubscriberPaginatedDateRangeTransactions(agent, page, size, range):Observable<any> {
    return this.http.get(baseUrl + '/transactions/subscribers/' + page + '/' + size + '?subscriberId=' + agent + '&' + range);
  }

  getDevicePaginatedDateRangeTransactions(imei, page, size, range):Observable<any> {
    return this.http.get(baseUrl + '/transactions/device/' + page + '/' + size + '?imei=' + imei + '&' + range);
  }

  getBillerPaginatedDateRangeTransactions(biller, page, size, range):Observable<any> {
    return this.http.get(baseUrl + '/transactions/device/' + page + '/' + size + '?imei=' + biller + '&' + range);
  }

  getBillerProductPaginatedDateRangeTransactions(product, page, size, range):Observable<any> {
    return this.http.get(baseUrl + '/transactions/device/' + page + '/' + size + '?imei=' + product + '&' + range);
  }

  // Account Balances
  getAgentBalances(ref): Observable<any> {
    return this.http.get(baseUrl + '/transactions/agent-balance-id/' + ref);
  }

  getSubscriberBalance(mobile): Observable<any> {
    return this.http.get(baseUrl + '/transactions/subscriber-balance-no-charge/' + mobile);
  }

  // subscriber bank accounts
  getSubscriberBankDetails(ref): Observable<any> {
    return this.http.get(`${baseUrl}/transactions/recipient-bank-accounts/${ref}`);
  }

  linkSubscriberBankAccount(data): Observable<any> {
    return this.http.post(baseUrl + '/transactions/link-account', data);
  }

  unlinkSubscriberBankAccount(ref): Observable<any> {
    return this.http.post(baseUrl + '/transactions/unlink-account/' + ref, null);
  }

  ApproveUnlinkSubscriberBankAccount(ref): Observable<any> {
    return this.http.post(baseUrl + '/transactions/approve-unlink-account/' + ref, null);
  }

  ApproveLinkSubscriberBankAccount(ref): Observable<any> {
    return this.http.post(baseUrl + '/transactions/approve-link-account/' + ref, null);
  }

  getLinkedRequests(page, size): Observable<any> {
    return this.http.get(baseUrl + '/transactions/awaiting-linking-accounts/' + page + '/' + size);
  }

  getUnLinkedRequests(page, size): Observable<any> {
    return this.http.get(baseUrl + '/transactions/awaiting-unlinking-accounts/' + page + '/' + size);
  }

  // Handle reversals
  initiateReversal(ref, reason): Observable<any> {
    return this.http.post(baseUrl + `/transactions/initiate-reversal/${ref}/${reason}`, null);
  }

  approveReversal(ref): Observable<any> {
    return this.http.post(baseUrl + `/transactions/validate-reversal/${ref}`, null);
  }

  // Link cards
  linkSubscriberCard(data): Observable<any> {
    return this.http.post(baseUrl + '/cards/link-account', data);
  }

  unlinkSubscriberCard(ref): Observable<any> {
    return this.http.post(baseUrl + '/cards/unlink-account/' + ref, null);
  }

  ApproveUnlinkSubscriberCard(ref): Observable<any> {
    return this.http.post(baseUrl + '/cards/approve-unlink-account/' + ref, null);
  }

  ApproveLinkSubscriberCard(ref): Observable<any> {
    return this.http.post(baseUrl + '/cards/approve-link-account/' + ref, null);
  }

  getLinkedCardsRequests(page, size): Observable<any> {
    return this.http.get(baseUrl + '/cards/awaiting-linking-accounts/' + page + '/' + size);
  }

  getUnLinkedCardsRequests(page, size): Observable<any> {
    return this.http.get(baseUrl + '/cards/awaiting-unlinking-accounts/' + page + '/' + size);
  }

  getSubscriberCards(mobile): Observable<any> {
    return this.http.get(baseUrl + '/cards/card/' + mobile);
  }

  repostTransaction(id: number): Observable<any> {
    return this.http.post(`${baseUrl}/transactions/adjustment/repost/${id}`, null)
  }

  depositMerchant(data): Observable<any> {
    return this.http.post(`${baseUrl}/transactions/deposit`, data)
  }

  getMerchantDeposits(page: number, size: number, status: string): Observable<any> {
    return this.http.get(`${baseUrl}/transactions/agent-deposit/${page}/${size}?status=${status}`)
  }

  approveMerchantDeposit(id: number): Observable<any> {
    return this.http.put(`${baseUrl}/transactions/approve-deposit/${id}`, null)
  }

  cancelMerchantDeposit(id: number): Observable<any> {
    return this.http.put(`${baseUrl}/transactions/cancel-deposit/${id}`, null)
  }
}
