import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AccountingService } from 'src/app/shared/services/accounting.service';
import { AuditsService } from 'src/app/shared/services/audits.service';
import * as fileSaver from 'file-saver';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-balances-summary',
  templateUrl: './balances-summary.component.html',
  styleUrls: ['./balances-summary.component.scss']
})
export class BalancesSummaryComponent implements OnInit {

  reports: any;
  loading: boolean = true;
  loading2: boolean;
  show: boolean;

  params: string;

  constructor(
    private toast: ToastrService,
    private accountsService: AccountingService,
    private auditService: AuditsService,
    public permService: PermissionsService,
    private router: Router,
    private authService: AuthService
  ) { 
    if(!this.permService.hasAccessAuthority('VIEW_TRUST_ACCOUNT')){
      toast.warning('Access Denied');
      router.navigateByUrl('landing-page');
    }
   }

  ngOnInit(): void {
    this.getReport();

    if(this.removeUnderscore(this.authService.getUserType()?.substring(5)) == 'RBZ MONITORING'){
      this.getCountStatistics();
      this.show = true;
    }

  }

  getReport(){
    this.accountsService.getSummaryOfBalances()
    .subscribe(
      data => {
        // console.log(data);
        this.reports = data;
        this.loading = false;
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          // this.toast.error('Failed to get Balances: ' + err.status);
        }
        this.loading = false;
      }
    )
  }

  getDateReport(ele){
    var date = ele.target.value;

    // this.accountsService.getDatedSummaryOfBalances(date)
    // .subscribe(
    //   data => {
    //     // console.log(data);
    //     this.reports = data;
    //     this.loading = false;
    //   },
    //   err => {
    //     console.log(err);
    //     if(err.status == 0){
    //       this.toast.error('Failed to Connect.');
    //     }
    //     else{
    //       this.toast.error('Failed to get Dated Balances: ' + err.status);
    //     }
    //     this.loading = false;
    //   }
    // )
    
  }

  exportReport(typ){
    if(!$('#myDate').val()){
      $('#myDate').css({'border-color':'red'});
      return false;
    }
    // else if(!$('#endDate').val()){
    //   $('#endDate').css({'border-color':'red'});
    //   return false;
    // }
    else{
      $('#myDate').css({'border-color':'#ced4da'});
      // $('#endDate').css({'border-color':'#ced4da'});

      var duran = {
        reportFormat: typ,
        startDate: $('#myDate').val(),
        // endDate: $('#endDate').val()
      }

      this.params = $.param(duran);
    }

    this.loading2 = true;
    this.auditService.exportTrustAccountsReport(this.params)
    .subscribe(
      resp => {
        this.loading2 = false;
        fileSaver.saveAs(resp, 'trust accounts report on ' + duran.startDate + '.' + typ.toLowerCase());
        // const url = window.URL.createObjectURL(resp);
			  // window.open(url);
      },
      err => {
        console.log(err);
        this.toast.error('Failed to get report: ' + err.status);
        this.loading2 = false;
      }
    )
  }

  getCountStatistics(){
    this.accountsService.getCountStatistics()
    .subscribe(
      data => {
        // console.log(data);
        
        if(data){
          $('#agentsCount').html(data.totalAgents);
          $('#billersCount').html(data.totalBillers);
          // $('#devicesCount').html(data.totalDevices);
          $('#subscribersCount').html(data.totalSubscribers);
        }
        else{
          $('#agentsCount').html('<i class="mdi mdi-window-close"></i>');
          $('#billersCount').html('<i class="mdi mdi-window-close"></i>');
          $('#devicesCount').html('<i class="mdi mdi-window-close"></i>');
          $('#subscribersCount').html('<i class="mdi mdi-window-close"></i>');
        }
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Count Statistics: ' + err.status);
        }

        $('#agentsCount').html('<i class="mdi mdi-window-close"></i>');
        $('#billersCount').html('<i class="mdi mdi-window-close"></i>');
        $('#devicesCount').html('<i class="mdi mdi-window-close"></i>');
        $('#subscribersCount').html('<i class="mdi mdi-window-close"></i>');

      }
    )
  }

  public removeUnderscore(str: string) {
    if(str){
      return str.replace(/_/g, " ");
    }
  }

}
