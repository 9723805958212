import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BillersService } from 'src/app/shared/services/billers.service';
import { ConfigsService } from 'src/app/shared/services/configs.service';
import { PermissionsService } from 'src/app/shared/services/permissions.service';

@Component({
  selector: 'app-subscriber-fees',
  templateUrl: './subscriber-fees.component.html',
  styleUrls: ['./subscriber-fees.component.scss']
})
export class SubscriberFeesComponent implements OnInit {

  fees: any;
  fee: any;
  profiles: any;
  profId: number;
  types: any;
  loading: boolean;
  loader: boolean;
  editLoader: boolean;
  addForm: FormGroup;
  profFees: FormArray;
  editForm: FormGroup;
  billers: any;
  products: any;

  constructor(
    private toast: ToastrService,
    private configService: ConfigsService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private billerService: BillersService,
    public permService: PermissionsService,
    private router: Router
  ) {
    if(!this.permService.hasAccessAuthority('VIEW_SUBSCRIBER_FEE')){
      toast.warning('Access Denied');
      router.navigateByUrl('landing-page');
    }
   }

  ngOnInit(): void {
    this.getProfiles();
    this.getTypes();
    this.getBillers();

    this.addForm = this.fb.group({
      profFees : this.fb.array([ this.createObject() ])
    });

    this.editForm = this.fb.group({
      subscriberProfileId: '',
      transactionTypeId: '',
      min: 0,
      max: 0,
      percentage: 0,
      fixed: 0,
      imtTaxFixed: 0,
      imtTaxPercentage: 0,
      productId: 0
    });
  }

  // convenience getters for easy access to form fields
  get f() { return this.addForm.controls; }
  get t() { return this.f.profFees as FormArray; }

  getProfiles(){
    this.configService.getSubscriberProfiles()
    .subscribe(
      data => {
        // console.log(data);
        this.profiles = data.content;
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Profiles: ' + err.status);
        }
      }
    )
  }

  showBillers(ref){
    // console.log(ref);

    var tid = this.addForm.value.profFees[ref].transactionTypeId;
    
    var typ = '';

    this.types.forEach(r => {
      if(r.id == tid){
        typ = r.name
      }
    });
    

    if(typ == "BILL_PAYMENT"){
      $('#billerArea' + ref).show();
      $('#productArea' + ref).show();
    }
    else{      
      $('#billerArea' + ref).hide();
      $('#productArea' + ref).hide();
    }
  }

  getBillers(){
    this.billerService.getAllBillers()
    .subscribe(
      data => {
        // console.log(data);
        this.billers = data.content;
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Billers: ' + err.status);
        }
      }
    )
  }
  
  loadProducts(ele){

    var ref = ele.target.value;

    // console.log(ref);
    

    this.billers.forEach(r => {
      if(r.id == ref){
        this.products = r.resultDtoList;
      }
    });

    console.log(this.products);    
  }

  getTypes(){
    this.configService.getTransactionTypes()
    .subscribe(
      data => {
        // console.log(data);
        this.types = data.content;
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Transaction Types: ' + err.status);
        }
      }
    )
  }

  createObject(): FormGroup {
    return this.fb.group({
      subscriberProfileId: '',
      transactionTypeId: '',
      min: 0,
      max: 0,
      percentage: 0,
      fixed: 0,
      imtTaxFixed: 0,
      imtTaxPercentage: 0,
      productId: ''
    });
  }

  handleProfChange(ele){
    // console.log(ele.target.value);
    
    this.profiles.forEach(r => {
      if(r.name == ele.target.value){
        this.profId = r.id;
      }
    });

    // console.log(this.profId);    
    this.getFees();
  }

  getFees(){
    if(this.profId){
      this.loading = true;

      this.configService.getSubscriberProfileFees(this.profId)
      .subscribe(
        data => {
          // console.log(data);
          this.loading = false;
          this.fees = data;
        },
        err => {
          console.log(err);
          if(err.status == 0){
            this.toast.error('Failed to Connect.');
          }
          else{
            this.toast.error('Failed to get Fees: ' + err.status);
          }
        }
      )
    }
  }

  onAddRow(e) {
    // alert($('#myCount').val());
    var num = Number($('#myCount').val());
    $('#myCount').val( num + 1);

    const numberOfRows = num || 1;
    if (this.t.length < numberOfRows) {
      for (let i = this.t.length; i < numberOfRows; i++) {
        this.t.push(
          this.createObject()
        );
      }
    }
  }

  addFees(form: FormGroup){
    // console.log(form.value);
    
    if(form.valid){
      this.loader = true;

      this.configService.createSubscriberFee(form.value.profFees)
      .subscribe(
        resp => {
          // console.log(resp);
          this.toast.success("Subscriber Fees Added.");
          this.modalService.dismissAll();
          this.getFees();
        },
        err => {
          console.log(err);
          if(err.status == 0){
            this.toast.error('Failed to Connect.');
          }
          else{
            this.toast.error('An Error Occured. Try Again.!');
          }
        }
      )
    }
    else{
      this.toast.warning("Missing Required Info.");
    }
  }

  loadFee(ref, modal){


    if(ref){
      this.fees.forEach(r => {
        if(r.id == ref){
          this.fee = r;
        }
      });
    }

    if(this.fee){
      this.editForm = this.fb.group({
        id: this.fee.id,
        // subscriberProfileId: this.fee.subscriberProfileId,
        // transactionTypeId: this.fee.transactionTypeId,
        min: this.fee.min,
        max: this.fee.max,
        percentage: this.fee.percentage,
        fixed: this.fee.fixed,
        imtTaxFixed: this.fee.imtTaxFixed,
        imtTaxPercentage: this.fee.imtTaxPercentage,
        productId: this.fee.productId
      });

      this.openModal(modal);
    }
  }

  updateFee(form: FormGroup){
    // console.log(form.value);
    
    if(form.valid){
      this.editLoader = true;

      this.configService.updateSubscriberFee(form.value)
      .subscribe(
        resp => {
          // console.log(resp);
          this.editLoader = false;
          this.toast.success("Subscriber Fees Updated.");
          this.modalService.dismissAll();
          this.editForm.reset();
          this.getFees();
        },
        err => {
          console.log(err);
          this.editLoader = false;
          if(err.status == 0){
            this.toast.error('Failed to Connect.');
          }
          else{
            this.toast.error('An Error Occured. Try Again.!');
          }
        }
      )
    }
    else{
      this.toast.warning("Missing Required Info.");
    }
  }

  openModal( modal ) {
    this.modalService.open( modal );
  }

}
