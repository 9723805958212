import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConfigsRoutingModule } from './configs-routing.module';
import { AgentProfileComponent } from './agent-profile/agent-profile.component';
import { SubscriberProfileComponent } from './subscriber-profile/subscriber-profile.component';
import { AgentFeesComponent } from './agent-fees/agent-fees.component';
import { SubscriberFeesComponent } from './subscriber-fees/subscriber-fees.component';
import { TransactionTypesComponent } from './transaction-types/transaction-types.component';
import { AgentLimitsComponent } from './agent-limits/agent-limits.component';
import { SubscriberLimitsComponent } from './subscriber-limits/subscriber-limits.component';
import { AgentVelocitiesComponent } from './agent-velocities/agent-velocities.component';
import { SubscriberVelocitiesComponent } from './subscriber-velocities/subscriber-velocities.component';
import { AgentCommissionsComponent } from './agent-commissions/agent-commissions.component';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [AgentProfileComponent, SubscriberProfileComponent, AgentFeesComponent, SubscriberFeesComponent, TransactionTypesComponent, AgentLimitsComponent, SubscriberLimitsComponent, AgentVelocitiesComponent, SubscriberVelocitiesComponent, AgentCommissionsComponent],
  imports: [
    CommonModule,
    ConfigsRoutingModule,
    ReactiveFormsModule
  ]
})
export class ConfigsModule { }
