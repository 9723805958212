import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuditsService } from 'src/app/shared/services/audits.service';
import * as fileSaver from 'file-saver';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
declare var $: any;

@Component({
  selector: 'app-audit-trails',
  templateUrl: './audit-trails.component.html',
  styleUrls: ['./audit-trails.component.scss']
})
export class AuditTrailsComponent implements OnInit {

  trails: any;
  loading: boolean = true;
  loading2: boolean;
  params: any;
  searchParams: any;
  viewAll: boolean;

  config: any;
  page = 1;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  totalItems = 0;
  showin = 0;

  constructor(
    private toast: ToastrService,
    private auditService: AuditsService,
    public permService: PermissionsService,
    private router: Router
  ) { 
    if(!this.permService.hasAccessAuthority('VIEW_AUDIT_TRAIL')){
      toast.warning('Access Denied');
      router.navigateByUrl('landing-page');
    }
   }

  ngOnInit(): void {
    this.getTrails();
  }

  handleSearch(){

    let qu = $('#searchVal').val();
    
    if(qu.length >= 2){
      // console.log(true);
      
      this.searchParams = 'search=username%3A' + qu;

      this.getTrails();
      this.viewAll = true;
    } 
    
  }

  viewAllDetails(){
    this.page = 1;
    this.searchParams = null;
    this.getTrails();
    this.viewAll = false;
  }

  getTrails(){
    this.loading = true;
    this.auditService.getManipulatedPaginatedTrails(this.page - 1, this.pageSize, this.params, this.searchParams)
    .subscribe(
      data => {
        // console.log(data);

        this.config = {
          itemsPerPage: this.pageSize,
          currentPage: data.pageable.pageNumber + 1,
          totalItems: data.totalElements,
          totalPages: data.totalPages
        };   

        if(this.page * this.pageSize > data.totalElements){
          this.showin = data.totalElements;
        }
        else{
          this.showin = this.page * this.pageSize;
        }

        this.trails = data.content;
        this.loading = false;
        // this.searchParams = null;
      },
      err => {
        console.log(err);
        this.loading = false;
        if(err.error.message){
          this.toast.error(err.error.message);
        }
        else{
          this.toast.error('An Error Occured. Please Try Again.!!!');
        }
      }
    )
  }

  handleDuration(){
    if(!$('#startDate').val()){
      $('#startDate').css({'border-color':'red'});
    }
    else if(!$('#endDate').val()){
      $('#endDate').css({'border-color':'red'});
    }
    else{
      $('#startDate').css({'border-color':'#ced4da'});
      $('#endDate').css({'border-color':'#ced4da'});

      var duran = {
        startDate: $('#startDate').val(),
        endDate: $('#endDate').val()
      }

      this.params = $.param(duran);

      // console.log(this.params);
      this.getTrails();
      
    }

  }

  handlePageChange(event) {
    this.page = event;
    this.getTrails();
  }

  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getTrails();
  }

  exportReport(typ){
    if(!$('#startDate').val()){
      $('#startDate').css({'border-color':'red'});
      return false;
    }
    else if(!$('#endDate').val()){
      $('#endDate').css({'border-color':'red'});
      return false;
    }
    else{
      $('#startDate').css({'border-color':'#ced4da'});
      $('#endDate').css({'border-color':'#ced4da'});

      var duran = {
        reportFormat: typ,
        startDate: $('#startDate').val(),
        endDate: $('#endDate').val()
      }

      this.params = $.param(duran);
    }

    this.loading2 = true;
    this.auditService.exportAuditTrails(this.params, this.searchParams)
    .subscribe(
      resp => {
        this.loading2 = false;
        fileSaver.saveAs(resp, 'audit trails from ' + duran.startDate + ' to ' + duran.endDate + '.' + typ.toLowerCase());
        // const url = window.URL.createObjectURL(resp);
			  // window.open(url);
      },
      err => {
        console.log(err);
        // this.toast.error('An Error Occured. Please Try Again.!');
        this.loading2 = false;
      }
    )
  }

}
