import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AgentsService } from 'src/app/shared/services/agents.service';
import * as fileSaver from 'file-saver';
import { FileService } from 'src/app/shared/services/file.service';
import { TransactionsService } from 'src/app/shared/services/transactions.service';
declare var $: any;

@Component({
  selector: 'app-agent-info',
  templateUrl: './agent-info.component.html',
  styleUrls: ['./agent-info.component.scss']
})
export class AgentInfoComponent implements OnInit {

  agent: any;
  agentId: any;
  loading: boolean = true;
  loading2: boolean;
  balances: any;

  operators: any;
  opLoader: boolean;
  operatorForm: FormGroup;

  devices: any;
  devLoader: boolean;
  deviceForm: FormGroup;

  constructor(
    private agentService: AgentsService,
    private toast: ToastrService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private fileService: FileService,
    private transactionsService: TransactionsService
  ) { }

  ngOnInit(): void {
    this.activateRoute.params.subscribe(
      params => {
        this.agentId = params['id'];
        this.getAgent();
      }
    );

    this.deviceForm = this.fb.group({
      imei: ["", Validators.required],
      type: ["", Validators.required],
      // status: ["", Validators.required],
      agentId: this.agentId
    });

    this.operatorForm = this.fb.group({
      firstname: ["", Validators.required],
      lastname: ["", Validators.required],
      email: ["", Validators.required],
      mobile: ["", Validators.required],
      // status: "ACTIVE",
      agentId: this.agentId
    });

  }

  getAgent(){
    this.agentService.getById(this.agentId)
    .subscribe(
      data => {
        // console.log(data);
        this.agent = data;
        this.loading = false;
        this.getBalances();
        this.getOperators();
        this.getDevices();
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Agent Info: ' + err.status);
        }
        this.loading = false;
      }
    )
  }

  getBalances(){
    this.transactionsService.getAgentBalances(this.agentId)
    .subscribe(
      data => {
        // console.log(data);
        this.balances = data;
      },
      (err) => {
        console.log(err);
        if ((err.status == 0)) {
          this.toast.error("Failed to Connect.");
        } else {
          this.toast.error("Failed to get Agent Balances: " + err.status);
        }
        this.loading = false;
      }
    )
  }

  getOperators(){
    this.agentService.getOperatorsByAgentId(this.agentId)
    .subscribe(
      data => {
        // console.log(data);
        this.operators = data.content;
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Agent Operators: ' + err.status);
        }
        this.loading = false;
      }
    )
  }

  addOperator(form: FormGroup){
    // console.log(form.value);
    if(!form.value.agentId){
      form.value.agentId = this.agentId;
    }

    if(form.valid){
      this.opLoader = true;
      this.agentService.createOperator(form.value)
      .subscribe(
        resp => {
          // console.log(resp);
          this.opLoader = false;
          this.toast.success('Operator Created');
          this.modalService.dismissAll();
          this.operatorForm.reset();
          // this.loading = true;
          this.getOperators();
        },
        error => {
          this.opLoader = false;
          console.log(error);
          if(error.error.message){
            this.toast.error(error.error.message);
          }
          else{
            this.toast.error('An Error Occured. Please Try Again.!!!');
          }
        }
      )
    }
    else{
      this.toast.warning('Missing required Info.');
    }  
    
  }

  getDevices(){
    this.agentService.getDeviceByAgentId(this.agentId)
    .subscribe(
      data => {
        // console.log(data);
        this.devices = data.content;
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Agent Devices: ' + err.status);
        }
        this.loading = false;
      }
    )
  }

  addDevice(form: FormGroup){
    // console.log(form.value);

    if(form.valid){
      this.devLoader = true;
      this.agentService.createDevice(form.value)
      .subscribe(
        resp => {
          // console.log(resp);
          this.devLoader = false;
          this.toast.success('Device Created');
          this.modalService.dismissAll();
          this.deviceForm.reset();
          // this.loading = true;
          this.getDevices();
        },
        error => {
          this.devLoader = false;
          console.log(error);
          if(error.error.message){
            this.toast.error(error.error.message);
          }
          else{
            this.toast.error('An Error Occured. Please Try Again.!!!');
          }
        }
      )
    }
    else{
      this.toast.warning('Missing required Info.');
    }  
    
  }

  getTransactions(){
    this.router.navigate(['/admin/merchants/transactions/' + this.agentId]);
  }

  showAll(){
    this.router.navigate(['/admin/merchants/all']);
  }

  updateAgent(ref){
    this.router.navigate(['/admin/merchants/update/' + this.agentId]);
  }

  openModal( modal ) {
    this.modalService.open( modal );
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  checkEmail(ele){
    // console.log(ele);

    var typed = ele.target.value;

    if(this.validateEmail(typed)){
      $(ele.target).removeClass('is-invalid');
      $(ele.target).addClass('is-valid');
    }
    else{
      $(ele.target).removeClass('is-valid');
      $(ele.target).addClass('is-invalid');
    }
  }

  filePreview(fname) {
    // alert(fname);
    if (fname) {
      this.loading2 = true;
      this.fileService.getFile(fname)
        .subscribe(
          resp => {
            this.loading2 = false;
            fileSaver.saveAs(resp, fname);
          },
          err => {
            console.log(err);
            // this.toast.error('An Error Occured. Please Try Again.!');
            this.loading2 = false;
          }
        );
    }
    else{
      this.toast.error('No File Found.');
    }
  }

}
