import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountingRoutingModule } from './accounting-routing.module';
import { AccountsStatementComponent } from './accounts-statement/accounts-statement.component';
import { BalancesSummaryComponent } from './balances-summary/balances-summary.component';
import { FeesReportComponent } from './fees-report/fees-report.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ReactiveFormsModule } from '@angular/forms';
import { DailyTransactionsComponent } from './daily-transactions/daily-transactions.component';
import { MonthlyTransactionsComponent } from './monthly-transactions/monthly-transactions.component';
import { TaxReportComponent } from './tax-report/tax-report.component';
import { EvalueStatementComponent } from './evalue-statement/evalue-statement.component';
import { SettlementComponent } from './settlement/settlement.component';
import { FailedTransactionsComponent } from './failed-transactions/failed-transactions.component';
import { ExceptionReportComponent } from './exception-report/exception-report.component';
import { ReportT24Component } from './report-t24/report-t24.component';
import { ReportBillPaymentComponent } from './report-bill-payment/report-bill-payment.component';


@NgModule({
  declarations: [AccountsStatementComponent, BalancesSummaryComponent, FeesReportComponent, DailyTransactionsComponent, MonthlyTransactionsComponent, TaxReportComponent, EvalueStatementComponent, SettlementComponent, FailedTransactionsComponent, ExceptionReportComponent, ReportT24Component, ReportBillPaymentComponent],
  imports: [
    CommonModule,
    AccountingRoutingModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    NgbModule
  ]
})
export class AccountingModule { }
