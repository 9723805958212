import { AuditsService } from 'src/app/shared/services/audits.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SubscribersService } from 'src/app/shared/services/subscribers.service';
import * as fileSaver from 'file-saver';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigsService } from 'src/app/shared/services/configs.service';
declare var $: any;

@Component({
  selector: 'app-all-subscribers',
  templateUrl: './all-subscribers.component.html',
  styleUrls: ['./all-subscribers.component.scss']
})
export class AllSubscribersComponent implements OnInit {

  subscribers: any;
  loading: boolean = true;
  loading2: boolean;
  searching: boolean;

  profiles: any;

  editForm: FormGroup;
  editLoader: boolean;

  config: any;
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  totalItems = 0;
  showin = 0;

  constructor(
    private toast: ToastrService,
    private subscriberService: SubscribersService,
    private router: Router,
    private auditService: AuditsService,
    public permService: PermissionsService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private configService: ConfigsService
  ) {
    if(!this.permService.hasAccessAuthority('VIEW_SUBSCRIBER')){
      toast.warning('Access Denied');
      router.navigateByUrl('landing-page');
    }
   }

  ngOnInit(): void {
    this.getSubscribers();

    this.editForm = this.fb.group({
      id: 0
    });

    this.getSubscriberProfiles();
  }

  getSubscriberProfiles(){
    this.configService.getSubscriberProfiles()
    .subscribe(
      data => {
        // console.log(data);
        this.profiles = data.content;
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Profiles: ' + err.status);
        }
      }
    )
  }

  handlePageChange(event) {
    this.page = event;
    this.getSubscribers();
  }

  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getSubscribers();
  }

  getSubscribers(){
    this.subscriberService.getPaginatedSubscribers(this.page - 1, this.pageSize)
    .subscribe(
      data => {
        // console.log(data);
        this.config = {
          itemsPerPage: this.pageSize,
          currentPage: data.pageable.pageNumber + 1,
          totalItems: data.totalElements,
          totalPages: data.totalPages
        };   

        if(this.page * this.pageSize > data.totalElements){
          this.showin = data.totalElements;
        }
        else{
          this.showin = this.page * this.pageSize;
        }
        this.subscribers = data.content;
        this.loading = false;
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Subscribers: ' + err.status);
        }
        this.loading = false;
      }
    );
  }

  loadSubscriber(ref){
    if(ref){
      this.router.navigate(['/admin/subscribers/info/' + ref]);
    }
  }

  searchSubscriberById(ele){
    let val = ele.target.value;
    // console.log(val);
    if(val){
      this.searching = true;
      this.subscriberService.getSubscriberById(val)
      .subscribe(
        data => {
          this.toast.success('Subscriber Found. Please wait...');
          // console.log(data);
          this.loadSubscriber(data.id);
          this.searching = false;
        },
        err => {
          this.searching = false;
          this.toast.error('Subscriber not found.');
          console.log(err);
          if(err.status == 0){
            this.toast.error('Failed to Connect.');
          }
          else{
            this.toast.error('Failed to get Subscribers: ' + err.status);
          }
        }
      )
    }
    else{
      this.toast.warning('Enter ID Number.');
    }
  }

  searchSubscriberByMobile(ele){
    let val = ele.target.value;
    // console.log(val);
    if(val){
      this.searching = true;
      this.subscriberService.getSubscriberByMobile(val)
      .subscribe(
        data => {
          this.toast.success('Subscriber Found. Please wait...');
          // console.log(data);
          this.loadSubscriber(data.id);
          this.searching = false;
        },
        err => {
          this.searching = false;
          this.toast.error('Subscriber not found.');
          console.log(err);
          if(err.status == 0){
            this.toast.error('Failed to Connect.');
          }
          else{
            this.toast.error('Failed to get Subscribers: ' + err.status);
          }
        }
      )
    }
    else{
      this.toast.warning('Enter mobile number.');
    }
  }

  exportReport(typ){
    
    this.loading2 = true;
    this.auditService.exportSubscribers(typ)
    .subscribe(
      resp => {
        this.loading2 = false;
        fileSaver.saveAs(resp, 'subscribers list.' + typ.toLowerCase());
      },
      err => {
        console.log(err);
        this.loading2 = false;
      }
    )
  }

  updateForm(ref, modal){
    let sd = null;

    if(ref){
      this.subscribers.forEach(r => {
        if(r.id == ref){
          sd = r;
        }
      });

      if(sd){
        this.editForm = this.fb.group({
          // agentName: [sd.agentName, Validators.required],
          city: [sd.city, Validators.required],
          dateOfBirth: [sd.dateOfBirth, Validators.required],
          email: [sd.email, Validators.required],
          firstname: [sd.firstname, Validators.required],
          gender: [sd.gender, Validators.required],
          id: [sd.id, Validators.required],
          idNumber: [sd.idNumber, Validators.required],
          idPhoto: [sd.idPhoto, Validators.required],
          lastname: [sd.lastname, Validators.required],
          mobile: [sd.mobile, Validators.required],
          // reason: [sd.reason],
          registrationDate: [sd.registrationDate, Validators.required],
          status: [sd.status, Validators.required],
          street: [sd.street, Validators.required],
          subscriberPhoto: [sd.subscriberPhoto, Validators.required],
          // subscriberProfile: [sd.subscriberProfile, Validators.required],
          suburb: [sd.suburb, Validators.required]
        });

        this.openModal(modal);
      }
    }
  }

  openModal(modal) {
    this.modalService.open(modal);
  }

  updateSubscriber(form: FormGroup){
    // console.log(form.value);

    if(form.valid){
      this.subscriberService.updateSubscriber(form.value)
      .subscribe(
        resp => {
          this.editLoader = false;
          console.log(resp);

          this.toast.success('Subscriber Updated.');
          this.modalService.dismissAll();
          this.getSubscribers();
        },
        err => {
          console.log(err);
          if ((err.status == 0)) {
            this.toast.error("Failed to Connect.");
          } else {
            this.toast.error("Failed to update: " + err.status);
          }
          this.editLoader = false;
      }
      )
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  checkEmail(ele){
    // console.log(ele);

    var typed = ele.target.value;

    if(this.validateEmail(typed)){
      $(ele.target).removeClass('is-invalid');
      $(ele.target).addClass('is-valid');
    }
    else{
      $(ele.target).removeClass('is-valid');
      $(ele.target).addClass('is-invalid');
    }
  }

}
