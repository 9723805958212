import { HttpEventType, HttpResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AgentsService } from "src/app/shared/services/agents.service";
import { ConfigsService } from "src/app/shared/services/configs.service";
import { FileService } from "src/app/shared/services/file.service";
declare var $: any;

var slidePage: any;
var progressText: any;
var progressCheck: any;
var bullet: any;
let current = 1;

@Component({
  selector: "app-new-agent",
  templateUrl: "./new-agent.component.html",
  styleUrls: ["./new-agent.component.scss"],
})
export class NewAgentComponent implements OnInit {
  agentForm: FormGroup;
  loader: boolean;
  prog_file: number;
  prog_file2: number;
  prog_file3: number;
  profiles: any;

  mylgas: string[] = [
    "AGEGE",
    "ORILE AGEGE",
    "AJEROMI LG",
    "IFELODUN",
    "ALIMOSHO",
    "AGBADO/OKE-ODO",
    "AYOBO IPAJA",
    "EGBE-IDIMU",
    "IGANDO-IKOTUN",
    "MOSAN OKUNOLA",
    "AMUWO ODOFIN",
    "ORIADE",
    "APAPA",
    "APAPA IGANMU",
    "BADAGRY",
    "BADAGRY West",
    "OLORUNDA",
    "EPE",
    "EREDO",
    "IKOSI EJINRIN",
    "ETI OSA",
    "ETI OSA EAST",
    "IRU- VICTORIA ISLAND",
    "IKOYI OBALANDE",
    "IBEJU LEKKI",
    "LEKKI",
    "IFAKO IJAYE",
    "OJOKORO",
    "IKEJA",
    "OJODU",
    "ONIGBONGBO",
    "IKORODU",
    "IKORODU NORTH",
    "IKORODU WEST",
    "IMOTA",
    "IJEDE",
    "IGBOBO-BAIYEKU",
    "KOSOFE",
    "IKOSI-ISHERI",
    "AGBOYI-KETU",
    "LAGOS ISLAND",
    "LAGOS ISLAND EAST",
    "LAGOS MAINLAND",
    "YABA",
    "MUSHIN",
    "ODI - OLOWO",
    "OJO LAG",
    "OTO AWORI",
    "IBA",
    "SOMOLU",
    "BARIGA",
    "OSHODI ISOLO",
    "ISOLO",
    "EJIGBO",
    "SURULERE",
    "COKER AGUDA",
    "ITIRE IKATE",
  ];

  constructor(
    private fb: FormBuilder,
    private toast: ToastrService,
    private agentService: AgentsService,
    private router: Router,
    private configService: ConfigsService,
    private fileService: FileService
  ) {}

  ngOnInit(): void {
    this.getProfiles();    

    slidePage = $(".slide-page");
    progressText = $(".step p");
    progressCheck = $(".step .check");
    bullet = $(".step .bullet");

    this.agentForm = this.fb.group({
      address: ["", Validators.required],
      mobile: ["", Validators.required],
      bvn: ["", Validators.required],
      governmentId: ["", Validators.required],
      localState: ["", Validators.required],
      numberOfEmployees: ["", Validators.required],
      numberOfOutlets: ["", Validators.required],
      lga: ["", Validators.required],
      email: ["", Validators.required],
      businessName: ["", Validators.required],
      agentProfileId: ["", Validators.required],
      profilePicture: ["", Validators.required],
      registrationDate: [this.getTodayDate()],
      contactPersonCreateDto: this.fb.group({
        firstname: ["", Validators.required],
        lastname: ["", Validators.required],
        email: ["", Validators.required],
        mobile: ["", Validators.required],
        landline: ["", Validators.required],
      }),
      administratorCreateDto: this.fb.group({
        firstname: ["", Validators.required],
        lastname: ["", Validators.required],
        email: ["", Validators.required],
        mobile: ["", Validators.required],
        landline: ["", Validators.required],
        agentId: [0],
      }),
      proofOfResidence: "",
      bank: ["", Validators.required],
      branch: ["", Validators.required],
      accountName: ["", Validators.required],
      accountNumber: ["", Validators.required]
    });
  }

  getTodayDate() {
    var tod = new Date();
    var dd = String(tod.getDate()).padStart(2, "0");
    var mm = String(tod.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yy = tod.getFullYear();

    return yy + "-" + mm + "-" + dd;
  }

  stepForward() {
    slidePage.css("marginLeft", "-25%");
    $(bullet[current - 1]).addClass("active");
    $(progressCheck[current - 1]).addClass("active");
    $(progressText[current - 1]).addClass("active");
    current += 1;
  }

  stepBack() {
    slidePage.css("marginLeft", "0%");
    $(bullet[current - 2]).removeClass("active");
    $(progressCheck[current - 2]).removeClass("active");
    $(progressText[current - 2]).removeClass("active");
    current -= 1;
  }

  showArea(sho, hid) {
    var f = this.agentForm.value;

    if (sho == 2) {
      if (
        !f.businessName ||
        !f.bvn ||
        !f.lga ||
        !f.mobile ||
        !f.email ||
        !f.numberOfEmployees ||
        !f.numberOfOutlets ||
        !f.agentProfileId ||
        !$("#file-name2").val()||
        !$("#file-name3").val() 
      ) {
        this.toast.warning("Missing Required Info.");
        return false;
      }
    }

    if (sho == 3) {
      if (!f.address || !f.localState || !$("#file-name").val()) {
        this.toast.warning("Missing Required Info.");
        return false;
      }
    }

    this.agentForm.patchValue({
      profilePicture: $("#file-name3").val(),
      governmentId: $("#file-name2").val(),
      proofOfResidence: $("#file-name").val(),
    });

    if (sho == 4) {
      var fc = f.contactPersonCreateDto;

      if (
        !fc.firstname ||
        !fc.lastname ||
        !fc.email ||
        !fc.mobile ||
        !fc.landline
      ) {
        this.toast.warning("Missing Required Info.");
        return false;
      }
    }

    if (hid) {
      $("#area" + hid).hide();
    }

    $("#area" + sho).show();

    if (sho > hid) {
      this.stepForward();
    } else {
      this.stepBack();
    }
  }

  uploadFile(event) {
    $("#fileSync").html('<i class="text-info mdi mdi-spin mdi-sync"></i>');
    var file = event.target.files[0];
    $("#file-name").val("uploadig...");

    this.fileService.uploadDocument(file).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this["prog_file"] = Math.round((100 * event.loaded) / event.total);
          // console.log(`Uploaded! ${idProgress}%`);
        } else if (event instanceof HttpResponse) {
          // console.log(event.body);
          var resp = event.body.fileName;
          // console.log(resp);

          $("#file-name").val(resp);
          $("#file-name").show();
          $("#upload-file").hide();
          $("#fileSync").html('<i class="mdi mdi-check text-success"></i>');
          this.toast.success("", "File Saved!!");
        }
      },
      (err) => {
        this.toast.error("Could not upload the file!!");
        $("#fileSync").html('<i class="mdi mdi-cancel text-danger"></i>');
      }
    );
  }

  uploadFile2(event) {
    $("#fileSync2").html('<i class="text-info mdi mdi-spin mdi-sync"></i>');
    var file = event.target.files[0];
    $("#file-name2").val("uploading...");

    this.fileService.uploadDocument(file).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this["prog_file2"] = Math.round((100 * event.loaded) / event.total);
          // console.log(`Uploaded! ${idProgress}%`);
        } else if (event instanceof HttpResponse) {
          // console.log(event.body);
          var resp = event.body.fileName;
          // console.log(resp);

          $("#file-name2").val(resp);
          $("#file-name2").show();
          $("#upload-file2").hide();
          $("#fileSync2").html('<i class="mdi mdi-check text-success"></i>');
          this.toast.success("", "File Saved!!");
        }
      },
      (err) => {
        this.toast.error("Could not upload the file!!");
        $("#fileSync2").html('<i class="mdi mdi-cancel text-danger"></i>');
      }
    );
  }

  uploadFile3(event) {
    $("#fileSync3").html('<i class="text-info mdi mdi-spin mdi-sync"></i>');
    var file = event.target.files[0];
    $("#file-name3").val("uploading...");

    this.fileService.uploadDocument(file).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this["prog_file3"] = Math.round((100 * event.loaded) / event.total);
          // console.log(`Uploaded! ${idProgress}%`);
        } else if (event instanceof HttpResponse) {
          // console.log(event.body);
          var resp = event.body.fileName;
          // console.log(resp);

          $("#file-name3").val(resp);
          $("#file-name3").show();
          $("#upload-file3").hide();
          $("#fileSync3").html('<i class="mdi mdi-check text-success"></i>');
          this.toast.success("", "File Saved!!");
        }
      },
      (err) => {
        this.toast.error("Could not upload the file!!");
        $("#fileSync3").html('<i class="mdi mdi-cancel text-danger"></i>');
      }
    );
  }

  getProfiles() {
    this.configService.getAgentProfiles().subscribe(
      (data) => {
        // console.log(data);
        this.profiles = data.content;
      },
      (err) => {
        console.log(err);
        if ((err.status == 0)) {
          this.toast.error("Failed to Connect.");
        } else {
          this.toast.error("Failed to get Profiles: " + err.status);
        }
      }
    );
  }

  addAgent(form: FormGroup) {
    console.log(form.value);

    if (form.valid) {
      this.stepForward();
      this.loader = true;

      this.agentService.createAgent(form.value).subscribe(
        (response) => {
          this.stepForward();
          // console.log(response);
          this.loader = false;
          this.toast.success("Merchant Added Successfully.!");
          this.router.navigate(["/admin/merchants/all"]);
        },
        (error) => {
          console.log(error);
          if (error.error.message) {
            this.toast.error(error.error.message);
          } else {
            this.toast.error("An Error Occured. Please Try Again.!!!");
          }
          this.loader = false;
        }
      );
    } else {
      this.toast.warning("Missing Required Info.");
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  checkEmail(ele) {
    // console.log(ele);

    var typed = ele.target.value;

    if (this.validateEmail(typed)) {
      $(ele.target).removeClass("is-invalid");
      $(ele.target).addClass("is-valid");
    } else {
      $(ele.target).removeClass("is-valid");
      $(ele.target).addClass("is-invalid");
    }
  }

  createName() {
    var fname = $("#manFname").val();
    var lname = $("#manLname").val();

    var uname = fname.charAt(0) + lname;

    // console.log(uname);

    this.agentForm.patchValue({
      username: uname,
    });
  }
}
