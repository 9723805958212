import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const baseUrl = environment.APIurl + '/akupay-accounting-service/api/v1';
const baseUrl2 = environment.APIurl + '/akupay-transaction-service/api/v1';
const baseUrl3 = environment.APIurl + '/akupay-banking-service/api/v1';

@Injectable({
  providedIn: 'root'
})
export class AccountingService {

  constructor(
    private http: HttpClient
  ) { }

  getStatementOfAccountsP2P(): Observable<any> {
    return this.http.get(baseUrl + '/transactions/statement-of-accounts/p2p');
  }

  getSummaryOfBalances(): Observable<any> {
    return this.http.get(baseUrl + '/transactions/summary-of-balances');
  }

  getDatedSummaryOfBalances(ref): Observable<any> {
    return this.http.get(baseUrl + '/transactions/summary-of-balances/' + ref);
  }

  // Dashboard
  getCountStatistics(): Observable<any> {
    return this.http.get(baseUrl + '/dashboard/platform');
  }

  getTransactionsStatistics(): Observable<any> {
    return this.http.get(baseUrl + '/dashboard/platform-transactions');
  }

  getCommissionsStatistics(): Observable<any> {
    return this.http.get(baseUrl + '/dashboard/platform-commissions');
  }

  // Reports
  getPaginatedDateRangeFeesReport(page, size, range): Observable<any> {
    return this.http.get(baseUrl2 + '/transactions/fees/date-range/' + page + '/' + size + '?' + range);
  }

  getPaginatedDateRangeTaxReport(page, size, range): Observable<any> {
    return this.http.get(baseUrl2 + '/transactions/tax/date-range/' + page + '/' + size + '?' + range);
  }

  getPaginatedDateRangeSettlementReport(page, size, range): Observable<any> {
    return this.http.get(baseUrl2 + '/transactions/settlement/date-range/' + page + '/' + size + '?' + range);
  }

  getPaginatedDateRangeExceptionReport(page, size, range): Observable<any> {
    return this.http.get(baseUrl2 + '/transactions/exception/date-range/' + page + '/' + size + '?' + range);
  }

  getPaginatedDateRangeEValueReport(page, size, range): Observable<any> {
    return this.http.get(baseUrl2 + '/transactions/emoney/date-range/' + page + '/' + size + '?' + range);
  }

  getPaginatedDateRangeT24Report(page, size, range): Observable<any> {
    return this.http.get(baseUrl3 + '/bank-transactions/report/generate-t24-report/' + page + '/' + size + '?' + range);
  }

  exportT24Report(params): Observable<any> {
    return this.http.get(baseUrl3 + '/bank-transactions/report/generate-t24-report?' + params, {responseType: 'blob'});
  }

}
