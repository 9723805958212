import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfigsService } from 'src/app/shared/services/configs.service';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
declare var $: any;

@Component({
  selector: 'app-agent-limits',
  templateUrl: './agent-limits.component.html',
  styleUrls: ['./agent-limits.component.scss']
})
export class AgentLimitsComponent implements OnInit {

  limits: any;
  limit: any;
  profiles: any;
  profId: number;
  types: any;
  loading: boolean;
  loader: boolean;
  editLoader: boolean;
  addForm: FormGroup;
  profFees: FormArray;
  editForm: FormGroup;

  constructor(
    private toast: ToastrService,
    private configService: ConfigsService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    public permService: PermissionsService,
    private router: Router
  ) {
    if(!this.permService.hasAccessAuthority('VIEW_AGENT_LIMIT')){
      toast.warning('Access Denied');
      router.navigateByUrl('landing-page');
    }
   }

  ngOnInit(): void {

    this.getProfiles();
    this.getTypes();

    this.addForm = this.fb.group({
      proflimits : this.fb.array([ this.createObject() ])
    });

    this.editForm = this.fb.group({
      agentProfileId: '',
      transactionTypeId: '',
      minDebit: 0,
      maxDebit: 0,
      minCredit: 0,
      maxCredit: 0
    });

  }

  // convenience getters for easy access to form fields
  get f() { return this.addForm.controls; }
  get t() { return this.f.proflimits as FormArray; }

  getProfiles(){
    this.configService.getAgentProfiles()
    .subscribe(
      data => {
        // console.log(data);
        this.profiles = data.content;
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Profiles: ' + err.status);
        }
      }
    )
  }

  getTypes(){
    this.configService.getTransactionTypes()
    .subscribe(
      data => {
        // console.log(data);
        this.types = data.content;
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Transaction Types: ' + err.status);
        }
      }
    )
  }

  createObject(): FormGroup {
    return this.fb.group({
      agentProfileId: '',
      transactionTypeId: '',
      minDebit: 0,
      maxDebit: 0,
      minCredit: 0,
      maxCredit: 0
    });
  }

  handleProfChange(ele){
    // console.log(ele.target.value);
    
    this.profiles.forEach(r => {
      if(r.name == ele.target.value){
        this.profId = r.id;
      }
    });

    // console.log(this.profId);    
    this.getLimits();
  }

  getLimits(){
    if(this.profId){
      this.loading = true;

      this.configService.getAgentLimits(this.profId)
      .subscribe(
        data => {
          // console.log(data);
          this.loading = false;
          this.limits = data;
        },
        err => {
          console.log(err);
          if(err.status == 0){
            this.toast.error('Failed to Connect.');
          }
          else{
            this.toast.error('Failed to get Limits: ' + err.status);
          }
        }
      )
    }
  }

  onAddRow(e) {
    // alert($('#myCount').val());
    var num = Number($('#myCount').val());
    $('#myCount').val( num + 1);

    const numberOfRows = num || 1;
    if (this.t.length < numberOfRows) {
      for (let i = this.t.length; i < numberOfRows; i++) {
        this.t.push(
          this.createObject()
        );
      }
    }
  }

  addLimit(form: FormGroup){
    // console.log(form.value);
    
    if(form.valid){
      this.loader = true;

      this.configService.createAgentLimit(form.value.proflimits)
      .subscribe(
        resp => {
          // console.log(resp);
          this.toast.success("Merchant Limits Added.");
          this.modalService.dismissAll();
          this.addForm.reset();
          this.loader = false;
          this.getLimits();
        },
        err => {
          console.log(err);
          this.loader = false;
          if(err.status == 0){
            this.toast.error('Failed to Connect.');
          }
          else{
            this.toast.error('An Error Occured. Try Again.!');
          }
        }
      )
    }
    else{
      this.toast.warning("Missing Required Info.");
    }
  }

  loadLimit(ref, modal){
    // alert('hey');
    // if(ref){
    //   this.limits.forEach(r => {
    //     if(r.id == ref){
    //       this.limit = ref;
    //     }
    //   });
    // }

    this.limit = this.limits[ref];

    if(this.limit){
      this.editForm = this.fb.group({
        id: this.limit.id,
        agentProfileId:this.limit.agentProfileId,
        transactionTypeId: this.limit.transactionTypeId,
        minDebit: this.limit.minDebit,
        maxDebit: this.limit.maxDebit,
        minCredit: this.limit.minCredit,
        maxCredit: this.limit.maxCredit
      });

      this.openModal(modal);
    }
  }

  UpdateLimit(form: FormGroup){
    // console.log(form.value);
    
    if(form.valid){
      this.editLoader = true;

      this.configService.updateAgentLimit(form.value)
      .subscribe(
        resp => {
          // console.log(resp);
          this.toast.success("Merchant Limits Updated.");
          this.modalService.dismissAll();
          this.editLoader = false;
          this.addForm.reset();
          this.getLimits();
        },
        err => {
          console.log(err);
          this.editLoader = false;
          if(err.status == 0){
            this.toast.error('Failed to Connect.');
          }
          else{
            this.toast.error('An Error Occured. Try Again.!');
          }
        }
      )
    }
    else{
      this.toast.warning("Missing Required Info.");
    }
  }

  openModal( modal ) {
    this.modalService.open( modal );
  }

  checkValue(src, ref){
    // console.log('check ' + src + ' at ' + ref);
    
    if(src == "debit"){
      if($('#dmin' + ref).val() > $('#dmax' + ref).val()){
        $('#dmin' + ref).css('borderColor', 'red');
        $('#dmax' + ref).css('borderColor', 'red');
        $('#btnSubmit').attr('disabled', true);
        $('#btnSubmit2').attr('disabled', true);
      }
      else{
        $('#dmin' + ref).css('borderColor', '#ebedf2');
        $('#dmax' + ref).css('borderColor', '#ebedf2');
        $('#btnSubmit').attr('disabled', false);
        $('#btnSubmit2').attr('disabled', false);
      }
    }
    else if(src == "credit"){
      if($('#cmin' + ref).val() > $('#cmax' + ref).val()){
        $('#cmin' + ref).css('borderColor', 'red');
        $('#cmax' + ref).css('borderColor', 'red');
        $('#btnSubmit').attr('disabled', true);
        $('#btnSubmit2').attr('disabled', true);
      }
      else{
        $('#cmin' + ref).css('borderColor', '#ebedf2');
        $('#cmax' + ref).css('borderColor', '#ebedf2');
        $('#btnSubmit').attr('disabled', false);
        $('#btnSubmit2').attr('disabled', false);
      }
    }
  }

}
