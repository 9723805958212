import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RouterModule } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { AgentsModule } from './agents/agents.module';
import { ConfigsModule } from './configs/configs.module';
import { SubscribersModule } from './subscribers/subscribers.module';
import { UsersModule } from './users/users.module';
import { AuditTrailsComponent } from './audit-trails/audit-trails.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { ReactiveFormsModule } from '@angular/forms';
import { AccountingModule } from './accounting/accounting.module';
import { LandingComponent } from './landing/landing.component';


@NgModule({
  declarations: [
    DashboardComponent,
    AuditTrailsComponent,
    LandingComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    AdminRoutingModule,
    RouterModule,
    ChartsModule,
    AgentsModule,
    ConfigsModule,
    SubscribersModule,
    UsersModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    AccountingModule
  ]
})
export class AdminModule { }
