import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  signInForm: FormGroup;
  loading: boolean;
  returnUrl: string = '';
  landingPage: string = 'admin/landing-page'
  visible: boolean;

  constructor(
    private activeRoute: ActivatedRoute,
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private toast: ToastrService,
    private storageService: StorageService,
    private permService: PermissionsService
  ) { }

  ngOnInit(): void {
    if(this.authService.isLoggedIn){
      this.router.navigateByUrl('admin/home');
    }

    this.activeRoute.queryParams.subscribe(
      params => this.returnUrl = params['returnUrl'] || 'admin/home'
    )

    localStorage.clear();

    this.signInForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      // clientSecret: "appclient@123",
      // clientId: "ADMIN"
    });
  }

  loginUser(){
   
    if(this.signInForm.valid){
      this.loading = true;

      this.authService.generalSignIn(this.signInForm.value)
      .subscribe(
        res => {
          // console.log(res);

          localStorage.setItem('access_token', this.storageService.encrypt(res.access_token));
          localStorage.setItem('user_type', this.storageService.encrypt(res.roles[0].name));
          localStorage.setItem('display_name', this.storageService.encrypt(res.firstName + ' ' + res.lastName));
          localStorage.setItem('user_name', this.storageService.encrypt(this.signInForm.value.username));
          
          // get user permissions
          let permissions = res.roles[0].permissions;

          let perms = [];
          permissions.forEach(r => {
            // console.log(r);
            perms.push(r.authority);
          });

          localStorage.setItem('authorities', JSON.stringify(perms));

          // console.log(this.signInForm.value.username);

          // window.location.href = 'admin/home';
          if(this.permService.hasAccessAuthority('VIEW_DASHBOARD'))
            this.router.navigateByUrl(this.returnUrl);
          else
            this.router.navigateByUrl(this.landingPage);
        },
        error => {
          console.log(error);

          if(error.status == 0){
            this.toast.error("Oops!! No Network.");
            this.loading = false;
          }
          if(error.status == 401){
            this.toast.error("Oops!! Access Denied.");
            this.loading = false;
          }
          else{
            if(error.error.message != null){
              let respo = error.error.message.split(" : ");
              // console.log(respo[0]);
              // let msg = JSON.parse(respo[0]);
              // console.log(msg);
              // this.toast.error(msg[0].error_description);

              if(respo[0].includes("Bad credentials")){
                this.loading = false;
                this.toast.error("Bad Credentials");
              }
              else if(error.error.message){
                this.loading = false;
                this.toast.error(error.error.message);
              }
              else{
                this.loading = false;
                this.toast.error("Invalid Credentials");
              }

            }
            else{
              this.toast.error('Invalid credentials');
              this.loading = false;
            }
          }
        }
      );
    }
    else{
      this.toast.warning('Enter Credentials.');
    }

  }

  myFunction() {
    
    if(this.visible){
      $('#myInput').attr('type', 'password');
      this.visible = false;
    }
    else{
      $('#myInput').attr('type', 'text');
      this.visible = true;
    }
  }

}
