import { ApproveLinkingComponent } from './approve-linking/approve-linking.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AllSubscribersComponent } from './all-subscribers/all-subscribers.component';
import { BatchReportComponent } from './batch-report/batch-report.component';
import { BulkRegistrationComponent } from './bulk-registration/bulk-registration.component';
import { SubscriberInfoComponent } from './subscriber-info/subscriber-info.component';
import { NewSubscriberComponent } from './new-subscriber/new-subscriber.component';
import { SubscriberTransactionsComponent } from './subscriber-transactions/subscriber-transactions.component';
import { CardsLinkingComponent } from './cards-linking/cards-linking.component';

const routes: Routes = [
  { path: 'new', component: NewSubscriberComponent },
  { path: 'registered', component: SubscriberTransactionsComponent },
  { path: 'all', component: AllSubscribersComponent },
  { path: 'info/:id', component: SubscriberInfoComponent },
  { path: 'bulk-registration', component: BulkRegistrationComponent },
  { path: 'batch-report', component: BatchReportComponent },
  { path: 'linking-approvals', component: ApproveLinkingComponent },
  { path: 'linking-cards-approvals', component: CardsLinkingComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubscribersRoutingModule { }
