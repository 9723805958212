import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfigsService } from 'src/app/shared/services/configs.service';
import { PermissionsService } from 'src/app/shared/services/permissions.service';

@Component({
  selector: 'app-agent-commissions',
  templateUrl: './agent-commissions.component.html',
  styleUrls: ['./agent-commissions.component.scss']
})
export class AgentCommissionsComponent implements OnInit {

  commissions: any;
  commission: any;
  profiles: any;
  profId: number;
  types: any;
  loading: boolean;
  loader: boolean;
  editLoader: boolean;
  addForm: FormGroup;
  profFees: FormArray;
  editForm: FormGroup;

  constructor(
    private toast: ToastrService,
    private configService: ConfigsService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    public permService: PermissionsService,
    private router: Router
  ) {
    if(!this.permService.hasAccessAuthority('VIEW_AGENT_COMMISSION')){
      toast.warning('Access Denied');
      router.navigateByUrl('landing-page');
    }
   }

  ngOnInit(): void {

    this.getProfiles();
    this.getTypes();

    this.addForm = this.fb.group({
      profComs : this.fb.array([ this.createObject() ])
    });

    this.editForm = this.fb.group({
      agentProfileId: '',
      transactionTypeId: '',
      min: 0,
      max: 0,
      percentage: 0,
      fixed: 0
    });

  }

  // convenience getters for easy access to form fields
  get f() { return this.addForm.controls; }
  get t() { return this.f.profComs as FormArray; }

  getProfiles(){
    this.configService.getAgentProfiles()
    .subscribe(
      data => {
        // console.log(data);
        this.profiles = data.content;
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Profiles: ' + err.status);
        }
      }
    )
  }

  getTypes(){
    this.configService.getTransactionTypes()
    .subscribe(
      data => {
        // console.log(data);
        this.types = data.content;
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Transaction Types: ' + err.status);
        }
      }
    )
  }

  createObject(): FormGroup {
    return this.fb.group({
      agentProfileId: '',
      transactionTypeId: '',
      min: 0,
      max: 0,
      percentage: 0,
      fixed: 0
    });
  }

  handleProfChange(ele){
    // console.log(ele.target.value);
    
    this.profiles.forEach(r => {
      if(r.name == ele.target.value){
        this.profId = r.id;
      }
    });

    // console.log(this.profId);    
    this.getCommissions();
  }

  getCommissions(){
    if(this.profId){
      this.loading = true;

      this.configService.getAgentCommissions(this.profId)
      .subscribe(
        data => {
          // console.log(data);
          this.loading = false;
          this.commissions = data;
        },
        err => {
          console.log(err);
          if(err.status == 0){
            this.toast.error('Failed to Connect.');
          }
          else{
            this.toast.error('Failed to get Commissions: ' + err.status);
          }
        }
      )
    }
  }

  onAddRow(e) {
    // alert($('#myCount').val());
    var num = Number($('#myCount').val());
    $('#myCount').val( num + 1);

    const numberOfRows = num || 1;
    if (this.t.length < numberOfRows) {
      for (let i = this.t.length; i < numberOfRows; i++) {
        this.t.push(
          this.createObject()
        );
      }
    }
  }

  addCommission(form: FormGroup){
    // console.log(form.value);
    
    if(form.valid){
      this.loader = true;

      this.configService.createAgentCommission(form.value.profComs)
      .subscribe(
        resp => {
          // console.log(resp);
          this.toast.success("Merchant Commissions Added.");
          this.modalService.dismissAll();
          this.addForm.reset();
          this.loader = false;
          this.getCommissions();
        },
        err => {
          console.log(err);
          this.loader = false;
          if(err.status == 0){
            this.toast.error('Failed to Connect.');
          }
          else{
            this.toast.error('An Error Occured. Try Again.!');
          }
        }
      )
    }
    else{
      this.toast.warning("Missing Required Info.");
    }
  }

  loadComm(ref, modal){
    if(ref){
      this.commissions.forEach(r => {
        if(r.id == ref){
          this.commission = r;
        }
      });
    }

    if(this.commission){
      this.editForm = this.fb.group({
        id: this.commission.id,
        // agentProfileId: this.commission.agentProfileId,
        // transactionTypeId: this.commission.transactionTypeId,
        min: this.commission.min,
        max: this.commission.max,
        percentage: this.commission.percentage,
        fixed: this.commission.fixed
      });

      this.openModal(modal);
    }
  }

  updateCommission(form: FormGroup){
    // console.log(form.value);
    
    if(form.valid){
      this.editLoader = true;

      this.configService.updateAgentCommission(form.value)
      .subscribe(
        resp => {
          // console.log(resp);
          this.editLoader = false;
          this.toast.success("Merchant Commissions Updated.");
          this.modalService.dismissAll();
          this.editForm.reset();
          this.getCommissions();
        },
        err => {
          console.log(err);
          this.editLoader = false;
          if(err.status == 0){
            this.toast.error('Failed to Connect.');
          }
          else{
            this.toast.error('An Error Occured. Try Again.!');
          }
        }
      )
    }
    else{
      this.toast.warning("Missing Required Info.");
    }
  }

  openModal( modal ) {
    this.modalService.open( modal );
  }

}
