import { PermissionsService } from './../../../shared/services/permissions.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AgentsService } from 'src/app/shared/services/agents.service';
import { ConfigsService } from 'src/app/shared/services/configs.service';
import { FileService } from 'src/app/shared/services/file.service';
import { SubscribersService } from 'src/app/shared/services/subscribers.service';
declare var $: any;

@Component({
  selector: 'app-new-subscriber',
  templateUrl: './new-subscriber.component.html',
  styleUrls: ['./new-subscriber.component.scss']
})
export class NewSubscriberComponent implements OnInit {

  addForm: FormGroup;
  loader: boolean;
  profiles: any;
  agents: any;
  prog_file: number;
  prog_file2: number;

  constructor(
    private fb: FormBuilder,
    private subscriberService: SubscribersService,
    private router: Router,
    private toast: ToastrService,
    private configService: ConfigsService,
    private agentService: AgentsService,
    private fileService: FileService,
    private permService: PermissionsService
  ) { 
    if(!this.permService.hasAccessAuthority('CREATE_SUBSCRIBER')){
      toast.warning('Access Denied');
      router.navigateByUrl('landing-page');
    }
  }

  ngOnInit(): void {

    this.addForm = this.fb.group({
      mobile: ['', Validators.required],
      email: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      idNumber: ['', Validators.required],
      registrationDate: [this.getTodayDate()],
      subscriberProfile: ["", Validators.required],
      dateOfBirth: ['', Validators.required],
      gender: ["", Validators.required],
      subscriberPhoto: ['', Validators.required],
      idPhoto: ['', Validators.required],
      agentId: ["", Validators.required]
    });

    this.getProfiles();
    this.getAgents();
  }

  getTodayDate() {
    var tod = new Date();
    var dd = String(tod.getDate()).padStart(2, "0");
    var mm = String(tod.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yy = tod.getFullYear();

    return yy + "-" + mm + "-" + dd;
  }

  getAgents(){
    this.agentService.getAllAgents()
    .subscribe(
      data => {
        // console.log(data.content);
        
        this.agents = data.content;
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Merchants: ' + err.status);
        }
      }
    )
  }

  getProfiles() {
    this.configService.getSubscriberProfiles().subscribe(
      (data) => {
        // console.log(data);
        this.profiles = data.content;
      },
      (err) => {
        console.log(err);
        if ((err.status == 0)) {
          this.toast.error("Failed to Connect.");
        } else {
          this.toast.error("Failed to get Profiles: " + err.status);
        }
      }
    );
  }

  uploadFile(event) {
    $("#fileSync").html('<i class="text-info mdi mdi-spin mdi-sync"></i>');
    var file = event.target.files[0];
    $("#file-name").val("uploadig...");

    this.fileService.uploadDocument(file).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this["prog_file"] = Math.round((100 * event.loaded) / event.total);
          // console.log(`Uploaded! ${idProgress}%`);
        } else if (event instanceof HttpResponse) {
          // console.log(event.body);
          var resp = event.body.fileName;
          // console.log(resp);

          $("#file-name").val(resp);
          $("#file-name").show();
          $("#upload-file").hide();
          $("#fileSync").html('<i class="mdi mdi-check text-success"></i>');
          this.toast.success("", "File Saved!!");
        }
      },
      (err) => {
        this.toast.error("Could not upload the file!!");
        $("#fileSync").html('<i class="mdi mdi-cancel text-danger"></i>');
      }
    );
  }

  uploadFile2(event) {
    $("#fileSync2").html('<i class="text-info mdi mdi-spin mdi-sync"></i>');
    var file = event.target.files[0];
    $("#file-name2").val("uploading...");

    this.fileService.uploadDocument(file).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this["prog_file2"] = Math.round((100 * event.loaded) / event.total);
          // console.log(`Uploaded! ${idProgress}%`);
        } else if (event instanceof HttpResponse) {
          // console.log(event.body);
          var resp = event.body.fileName;
          // console.log(resp);

          $("#file-name2").val(resp);
          $("#file-name2").show();
          $("#upload-file2").hide();
          $("#fileSync2").html('<i class="mdi mdi-check text-success"></i>');
          this.toast.success("", "File Saved!!");
        }
      },
      (err) => {
        this.toast.error("Could not upload the file!!");
        $("#fileSync2").html('<i class="mdi mdi-cancel text-danger"></i>');
      }
    );
  }

  addSubscriber(form: FormGroup){
    console.log(form.value);

    this.addForm.patchValue({
      subscriberPhoto: $('#file-name').val(),
      idPhoto: $('#file-name2').val()
    });

    if(form.valid){
      this.loader = true;
      this.subscriberService.createSubscriber(form.value)
      .subscribe(
        resp => {
          console.log(resp);
          this.toast.success('Subscriber Created');
          this.router.navigate(['/admin/subscribers/registered']);
        },
        err => {
          console.log(err);
          this.loader = false;
          if ((err.status == 0)) {
            this.toast.error("Failed to Connect.");
          }
          else if (err.error.message) {
            this.toast.error(err.error.message);
          } else {
            this.toast.error("Failed to create Subscriber: " + err.status);
          }
        }
      )
    }
    else{
      this.toast.warning("Missing Required Info.");
    }
    
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  checkEmail(ele){
    // console.log(ele);

    var typed = ele.target.value;

    if(this.validateEmail(typed)){
      $(ele.target).removeClass('is-invalid');
      $(ele.target).addClass('is-valid');
    }
    else{
      $(ele.target).removeClass('is-valid');
      $(ele.target).addClass('is-invalid');
    }
  }

}
