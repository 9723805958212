import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AccountingService } from 'src/app/shared/services/accounting.service';
import { AuditsService } from 'src/app/shared/services/audits.service';
import * as fileSaver from 'file-saver';
import { PermissionsService } from 'src/app/shared/services/permissions.service';

@Component({
  selector: 'app-accounts-statement',
  templateUrl: './accounts-statement.component.html',
  styleUrls: ['./accounts-statement.component.scss']
})
export class AccountsStatementComponent implements OnInit {

  reports: any;
  loading: boolean = true;

  loading2: boolean;
  params: string;

  constructor(
    private toast: ToastrService,
    private accountsService: AccountingService,
    private auditService: AuditsService,
    public permService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.getReport();
  }

  getReport(){
    this.accountsService.getStatementOfAccountsP2P()
    .subscribe(
      data => {
        // console.log(data);
        this.reports = data;
        this.loading = false;
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Statements: ' + err.status);
        }
        this.loading = false;
      }
    )
  }

  exportReport(typ){
    if(!$('#startDate').val()){
      $('#startDate').css({'border-color':'red'});
      return false;
    }
    else if(!$('#endDate').val()){
      $('#endDate').css({'border-color':'red'});
      return false;
    }
    else{
      $('#startDate').css({'border-color':'#ced4da'});
      $('#endDate').css({'border-color':'#ced4da'});

      var duran = {
        reportFormat: typ,
        startDate: $('#startDate').val(),
        endDate: $('#endDate').val()
      }

      this.params = $.param(duran);
    }

    this.loading2 = true;
    this.auditService.exportP2PStatementReport(this.params)
    .subscribe(
      resp => {
        this.loading2 = false;
        fileSaver.saveAs(resp, 'p2p statement report from ' + duran.startDate + ' to ' + duran.endDate + '.' + typ.toLowerCase());
        // const url = window.URL.createObjectURL(resp);
			  // window.open(url);
      },
      err => {
        console.log(err);
        // this.toast.error('An Error Occured. Please Try Again.!');
        this.loading2 = false;
      }
    )
  }

}
