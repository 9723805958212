import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FileService } from 'src/app/shared/services/file.service';
import { SubscribersService } from 'src/app/shared/services/subscribers.service';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-subscriber-transactions',
  templateUrl: './subscriber-transactions.component.html',
  styleUrls: ['./subscriber-transactions.component.scss']
})
export class SubscriberTransactionsComponent implements OnInit {

  subscriber: any;

  pendingSubscribers: any;
  declinedSubscribers: any;
  loading: boolean;
  loading2: boolean;

  loader: boolean;
  loader2: boolean;

  pending: boolean;
  declined: boolean;  
  approve: boolean;
  decline: boolean;

  config: any;
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  totalItems = 0;
  showin = 0;

  config2: any;
  page2 = 1;
  count2 = 0;
  pageSize2 = 10;
  pageSizes2 = [10, 25, 50, 100];
  totalItems2 = 0;
  showin2 = 0;

  constructor(
    private toast: ToastrService,
    private subscriberService: SubscribersService,
    private router: Router,
    private modalService: NgbModal,
    private fileService: FileService
  ) { }

  ngOnInit(): void {

  }

  handleSelection(ele){
    let val = ele.target.value;

    if(val == 'pending'){
      this.pending = true;
      this.declined = false;
      this.loading = true;
      this.getPendingSubscribers();
    }
    else if(val == 'declined'){
      this.pending = false;
      this.declined = true;
      this.loading = true;
      this.getDeclinedSubscribers();
    }
    else{
      this.toast.warning('Wrong Selection.');
    }
  }

  handleOption(ele){
    let val = ele.target.value;

    if(val == 'approve'){
      this.approve = true;
      this.decline = false;
    }
    else if(val == 'decline'){
      this.approve = false;
      this.decline = true;
    }
    else{
      this.toast.warning('Wrong Option.');
    }
  }

  handlePageChange(event) {
    this.page = event;
    this.getPendingSubscribers();
  }

  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getPendingSubscribers();
  }

  handlePageChange2(event) {
    this.page = event;
    this.getDeclinedSubscribers();
  }

  handlePageSizeChange2(event) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getDeclinedSubscribers();
  }

  getPendingSubscribers() {
    this.subscriberService.getPaginatedPendingSubscribers(this.page - 1, this.pageSize)
    .subscribe(
      data => {
        // console.log(data);
        this.config = {
          itemsPerPage: this.pageSize,
          currentPage: data.pageable.pageNumber + 1,
          totalItems: data.totalElements,
          totalPages: data.totalPages
        };   

        if(this.page * this.pageSize > data.totalElements){
          this.showin = data.totalElements;
        }
        else{
          this.showin = this.page * this.pageSize;
        }
        this.pendingSubscribers = data.content;
        this.loading = false;
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Pending Subscribers: ' + err.status);
        }
        this.loading = false;
      }
    );
  }

  getDeclinedSubscribers() {
    this.subscriberService.getPaginatedDeclinedSubscribers(this.page - 1, this.pageSize)
    .subscribe(
      data => {
        // console.log(data);
        this.config2 = {
          itemsPerPage: this.pageSize2,
          currentPage: data.pageable.pageNumber + 1,
          totalItems: data.totalElements,
          totalPages: data.totalPages
        };   

        if(this.page2 * this.pageSize2 > data.totalElements){
          this.showin2 = data.totalElements;
        }
        else{
          this.showin2 = this.page2 * this.pageSize2;
        }
        this.declinedSubscribers = data.content;
        this.loading = false;
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Declined Subscribers: ' + err.status);
        }
        this.loading = false;
      }
    );
  }

  openModal(modal){
    this.modalService.open(modal, {centered: true, size: 'lg'});
  }

  approveSubscriber(){
    // console.log(this.subscriber.id);
    this.loader = true;

    this.subscriberService.approveSubscriber(this.subscriber.id)
    .subscribe(
      resp => {
        // console.log(resp);
        this.modalService.dismissAll();
        this.toast.success('Subscriber Registration Approved.');
        this.loader = false;
        this.loading = true;
        this.getPendingSubscribers();
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Approve Subscriber: ' + err.status);
        }
        this.loader = false;
      }
    )
  }

  declineSubscriber(){
    // console.log(this.subscriber.id, $('#declineReason').val());
    
    if($('#declineReason').val()){
      this.loader2 = true;
      this.subscriberService.declineSubscriber(this.subscriber.id, $('#declineReason').val())
      .subscribe(
        resp => {
          // console.log(resp);
          this.modalService.dismissAll();
          this.toast.success('Subscriber Registration Declined.');
          this.loader2 = false;
          this.loading = true;
          this.getPendingSubscribers();
        },
        err => {
          console.log(err);
          if(err.status == 0){
            this.toast.error('Failed to Connect.');
          }
          else{
            this.toast.error('Failed to get Decline Subscriber: ' + err.status);
          }
          this.loader2 = false;
        }
      )
    }
    else{
      this.toast.warning('Enter Reason.');
    }
  }

  loadSubscriber(ref, modal){
    this.pendingSubscribers.forEach(r => {
      if(r.id == ref){
        this.subscriber = r;
      }
    });

    // console.log(this.subscriber);
    if(this.subscriber){
      this.openModal(modal);
    }
  }

  filePreview(fname) {
    // alert(fname);
    if (fname) {
      this.loading2 = true;
      this.fileService.getSubscriberFile(fname)
        .subscribe(
          resp => {
            this.loading2 = false;
            fileSaver.saveAs(resp, fname);
          },
          err => {
            console.log(err);
            // this.toast.error('An Error Occured. Please Try Again.!');
            this.loading2 = false;
          }
        );
    }
    else{
      this.toast.error('No File Found.');
    }
  }

}
