import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AgentsService } from 'src/app/shared/services/agents.service';

@Component({
  selector: 'app-update-agent',
  templateUrl: './update-agent.component.html',
  styleUrls: ['./update-agent.component.scss']
})
export class UpdateAgentComponent implements OnInit {

  agent: any;
  agentId: any;
  loading: boolean = true;
  loader: boolean;
  agentForm: FormGroup;

  mylgas: string[] = [
    "AGEGE",
    "ORILE AGEGE",
    "AJEROMI LG",
    "IFELODUN",
    "ALIMOSHO",
    "AGBADO/OKE-ODO",
    "AYOBO IPAJA",
    "EGBE-IDIMU",
    "IGANDO-IKOTUN",
    "MOSAN OKUNOLA",
    "AMUWO ODOFIN",
    "ORIADE",
    "APAPA",
    "APAPA IGANMU",
    "BADAGRY",
    "BADAGRY West",
    "OLORUNDA",
    "EPE",
    "EREDO",
    "IKOSI EJINRIN",
    "ETI OSA",
    "ETI OSA EAST",
    "IRU- VICTORIA ISLAND",
    "IKOYI OBALANDE",
    "IBEJU LEKKI",
    "LEKKI",
    "IFAKO IJAYE",
    "OJOKORO",
    "IKEJA",
    "OJODU",
    "ONIGBONGBO",
    "IKORODU",
    "IKORODU NORTH",
    "IKORODU WEST",
    "IMOTA",
    "IJEDE",
    "IGBOBO-BAIYEKU",
    "KOSOFE",
    "IKOSI-ISHERI",
    "AGBOYI-KETU",
    "LAGOS ISLAND",
    "LAGOS ISLAND EAST",
    "LAGOS MAINLAND",
    "YABA",
    "MUSHIN",
    "ODI - OLOWO",
    "OJO LAG",
    "OTO AWORI",
    "IBA",
    "SOMOLU",
    "BARIGA",
    "OSHODI ISOLO",
    "ISOLO",
    "EJIGBO",
    "SURULERE",
    "COKER AGUDA",
    "ITIRE IKATE",
  ];

  constructor(
    private agentService: AgentsService,
    private toast: ToastrService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.activateRoute.params.subscribe(
      params => {
        this.agentId = params['id'];
        this.getAgent();
      }
    );

    this.agentForm = this.fb.group({
      id: 0,
      name: ["", Validators.required],
      mobile: ["", Validators.required],
      address: ["", Validators.required],
      email: ["", Validators.required],
      bvn: ["", Validators.required],
      governmentId: ["", Validators.required],
      lga: ["", Validators.required],
      localState: ["", Validators.required],
      businessName: ["", Validators.required],
      numberOfEmployees: 0,
      numberOfOutlets: 0,
      proofOfResidence: ["", Validators.required],
      profilePicture: ["", Validators.required],
      registrationDate: "2021-03-08T09:39:26.884Z",
      status: "ACTIVE",
      agentProfileId: 0,
      bank: ["", Validators.required],
      branch: ["", Validators.required],
      accountName: ["", Validators.required],
      accountNumber: ["", Validators.required]
    });
  }

  getAgent(){
    this.agentService.getById(this.agentId)
    .subscribe(
      data => {
        // console.log(data);
        this.agent = data;
        this.loading = false;
        this.loadAgentData();
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Agent Info: ' + err.status);
        }
        this.loading = false;
      }
    )
  }

  loadAgentData(){
    if(this.agent){
      this.agentForm = this.fb.group({
        id: this.agent.id,
        mobile: this.agent.mobile,
        address: this.agent.address,
        email: this.agent.email,
        bvn: this.agent.bvn,
        governmentId: this.agent.governmentId,
        lga: this.agent.lga,
        localState: this.agent.localState,
        businessName: this.agent.businessName,
        numberOfEmployees: this.agent.numberOfEmployees,
        numberOfOutlets: this.agent.numberOfOutlets,
        proofOfResidence: this.agent.proofOfResidence,
        profilePicture: this.agent.profilePicture,
        registrationDate: null,
        status: this.agent.status,
        agentProfileId: this.agent.agentProfileId,
        bank: this.agent.bank,
        branch: this.agent.branch,
        accountName: this.agent.accountName,
        accountNumber: this.agent.accountNumber
      });
    }
  }

  showAll(){
    this.router.navigate(['/admin/merchants/all']);
  }

  updateAgent(form: FormGroup){
    console.log(form.value);

    this.loader = true;
    this.agentService.updateAgent(form.value)
      .subscribe(
        resp => {
          console.log(resp);
          this.loader = false;
          this.showAll();
        }
      ),
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to update Merchant Info: ' + err.status);
        }
        this.loader = false;
      }

  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  checkEmail(ele){
    // console.log(ele);

    var typed = ele.target.value;

    if(this.validateEmail(typed)){
      $(ele.target).removeClass('is-invalid');
      $(ele.target).addClass('is-valid');
    }
    else{
      $(ele.target).removeClass('is-valid');
      $(ele.target).addClass('is-invalid');
    }
  }

}
