import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfigsService } from 'src/app/shared/services/configs.service';
import { PermissionsService } from 'src/app/shared/services/permissions.service';

@Component({
  selector: 'app-transaction-types',
  templateUrl: './transaction-types.component.html',
  styleUrls: ['./transaction-types.component.scss']
})
export class TransactionTypesComponent implements OnInit {

  types: any;
  etype: any;
  loading: boolean = true;
  loader: boolean;
  editLoader: boolean;
  addForm: FormGroup;
  editForm: FormGroup;

  constructor(
    private toast: ToastrService,
    private configService: ConfigsService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    public permService: PermissionsService,
    private router: Router
  ) {
    if(!this.permService.hasAccessAuthority('VIEW_TRANSACTION_TYPE')){
      toast.warning('Access Denied');
      router.navigateByUrl('landing-page');
    }
   }

  ngOnInit(): void {
    this.getTypes();

    this.addForm = this.fb.group({
      name: ["", Validators.required],
      description: ["", Validators.required]
    });

    this.editForm = this.fb.group({
      id: 0,
      name: ["", Validators.required],
      description: ["", Validators.required]
    });

  }

  getTypes(){
    this.configService.getTransactionTypes()
    .subscribe(
      data => {
        // console.log(data);
        this.types = data.content;
        this.loading = false;
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Types: ' + err.status);
        }
        this.loading = false;
      }
    )
  }

  openModal( modal ) {
    this.modalService.open( modal );
  }

  addType(form: FormGroup){
    // console.log(form.value);
    
    if(form.valid){
      this.loader = true;

      this.configService.createTransactionType(form.value)
      .subscribe(
        resp => {
          // console.log(resp);
          this.loader = false;
          this.toast.success('Transaction Type Created');
          this.modalService.dismissAll();
          this.addForm.reset();
          this.loading = true;
          this.getTypes();
        },
        error => {
          this.loader = false;
          console.log(error);
          if(error.error.message){
            this.toast.error(error.error.message);
          }
          else{
            this.toast.error('An Error Occured. Please Try Again.!!!');
          }
        }
      )
    }
  }

  loadType(ref){
    this.types.forEach(r => {
      if(r.id == ref){
        this.etype = r;
      }
    });

    // console.log(this.profile);

    if(this.etype){
      this.editForm = this.fb.group({
        id: this.etype.id,
        name: [this.etype.name, Validators.required],
        description: [this.etype.description, Validators.required]
      });
    }
    
  }

  updateType(form: FormGroup){
    // console.log(form.value);
    
    if(form.valid){
      this.editLoader = true;

      this.configService.updateTransactionType(form.value)
      .subscribe(
        resp => {
          // console.log(resp);
          this.editLoader = false;
          this.toast.success('Transaction Type Updated');
          this.modalService.dismissAll();
          this.loading = true;
          this.editForm.reset();
          this.getTypes();
        },
        error => {
          this.editLoader = false;
          console.log(error);
          if(error.error.message){
            this.toast.error(error.error.message);
          }
          else{
            this.toast.error('An Error Occured. Please Try Again.!!!');
          }
        }
      )
    }
  }


}
