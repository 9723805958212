import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubscribersRoutingModule } from './subscribers-routing.module';
import { AllSubscribersComponent } from './all-subscribers/all-subscribers.component';
import { SubscriberInfoComponent } from './subscriber-info/subscriber-info.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ReactiveFormsModule } from '@angular/forms';
import { SubscriberTransactionsComponent } from './subscriber-transactions/subscriber-transactions.component';
import { BulkRegistrationComponent } from './bulk-registration/bulk-registration.component';
import { BatchReportComponent } from './batch-report/batch-report.component';
import { ApproveLinkingComponent } from './approve-linking/approve-linking.component';
import { NewSubscriberComponent } from './new-subscriber/new-subscriber.component';
import { CardsLinkingComponent } from './cards-linking/cards-linking.component';


@NgModule({
  declarations: [AllSubscribersComponent, SubscriberInfoComponent, SubscriberTransactionsComponent, BulkRegistrationComponent, BatchReportComponent, ApproveLinkingComponent, NewSubscriberComponent, CardsLinkingComponent],
  imports: [
    CommonModule,
    SubscribersRoutingModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    NgbModule
  ]
})
export class SubscribersModule { }
