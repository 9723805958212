import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const baseUrl = environment.APIurl + '/akupay-agent-service/api/v1';

@Injectable({
  providedIn: 'root'
})
export class AgentsService {

  constructor(
    private http: HttpClient
  ) { }

  getAllAgents(): Observable<any> {
    return this.http.get(baseUrl + '/agent/0/1000');
  }

  getPaginatedAgents(page, size): Observable<any> {
    return this.http.get(baseUrl + '/agent/' + page + '/' + size);
  }

  getById(id): Observable<any> {
    return this.http.get(`${baseUrl}/agent/${id}`);
  }

  createAgent(data): Observable<any> {
    return this.http.post(baseUrl + '/agent', data);
  }

  updateAgent(data): Observable<any> {
    return this.http.put(`${baseUrl}/agent`, data);
  }

  changeAgentStatus(id, status): Observable<any> {
    return this.http.get(baseUrl + '/agent/change-status/' + id + '?active=' + status);
  }

  getRegisteredAgentsCount(): Observable<any> {
    return this.http.get(`${baseUrl}/agent/number-of-registered-agents`);
  }

  searchAgents(param): Observable<any> {
    return this.http.post(baseUrl + '/agent/search/0/100?text=' + param, null);
  }


  // handle devices
  getAllDevices(): Observable<any> {
    return this.http.get(baseUrl + '/devices/0/1000');
  }

  getPaginatedDevices(page, size): Observable<any> {
    return this.http.get(baseUrl + '/devices/' + page + '/' + size);
  }

  getDeviceByAgentId(id): Observable<any> {
    return this.http.get(`${baseUrl}/devices/${id}/0/100`);
  }

  getDeviceById(id): Observable<any> {
    return this.http.get(`${baseUrl}/device/${id}`);
  }

  createDevice(data): Observable<any> {
    return this.http.post(baseUrl + '/device', data);
  }

  updateDevice(data): Observable<any> {
    return this.http.put(`${baseUrl}/device`, data);
  }

   // handle banks
  getPaginatedBanks(page, size): Observable<any> {
    return this.http.get(baseUrl + '/bank/' + page + '/' + size);
  }

  getBankById(id): Observable<any> {
    return this.http.get(`${baseUrl}/bank/${id}`);
  }

  createBank(data): Observable<any> {
    return this.http.post(baseUrl + '/bank', data);
  }

  updateBank(data): Observable<any> {
    return this.http.put(`${baseUrl}/bank`, data);
  }

  // handle operators
  getOperatorsByAgentId(id): Observable<any> {
    return this.http.get(`${baseUrl}/operators/${id}/0/100`);
  }

  getPaginatedOperators(page, size): Observable<any> {
    return this.http.get(baseUrl + '/operators/' + page + '/' + size);
  }

  getOperatorById(id): Observable<any> {
    return this.http.get(`${baseUrl}/operator/${id}`);
  }

  createOperator(data): Observable<any> {
    return this.http.post(baseUrl + '/operator', data);
  }

  updateOperator(data): Observable<any> {
    return this.http.put(`${baseUrl}/operator`, data);
  }

  resetOperator(ref): Observable<any> {
    return this.http.put(`${baseUrl}/operator-reset/${ref}`, null);
  }

}
