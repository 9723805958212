import { Router } from '@angular/router';
import { PermissionsService } from './../../shared/services/permissions.service';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AccountingService } from 'src/app/shared/services/accounting.service';
import { ConfigsService } from 'src/app/shared/services/configs.service';
import { TransactionsService } from 'src/app/shared/services/transactions.service';
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  toggleProBanner(event) {
    // console.log("123");
    event.preventDefault();
    document.querySelector('body').classList.toggle('removeProbanner');
  }

  types: any;
  loading: boolean;
  transLoading: boolean;
  commsLoading: boolean;
  transactions: any;
  selected: string;
  statsTransactions: any;
  Commissions: any;

  constructor(
    private configService: ConfigsService,
    private toast: ToastrService,
    private transactionsService: TransactionsService,
    private accountsService: AccountingService,
    private permService: PermissionsService,
    private router: Router
  ) { 

    if(!this.permService.hasAccessAuthority('VIEW_DASHBOARD')){
      toast.warning('Access Denied');
      router.navigateByUrl('landing-page');
    }
  }

  ngOnInit() {

    this.getCountStatistics();
    this.getTransactionStatistics();
    this.getCommissionStatistics();
    this.getTypes();

  }

  getCountStatistics(){
    this.accountsService.getCountStatistics()
    .subscribe(
      data => {
        // console.log(data);
        
        if(data){
          $('#agentsCount').html(data.totalAgents);
          $('#billersCount').html(data.totalBillers);
          // $('#devicesCount').html(data.totalDevices);
          $('#subscribersCount').html(data.totalSubscribers);
        }
        else{
          $('#agentsCount').html('<i class="mdi mdi-window-close"></i>');
          $('#billersCount').html('<i class="mdi mdi-window-close"></i>');
          // $('#devicesCount').html('<i class="mdi mdi-window-close"></i>');
          $('#subscribersCount').html('<i class="mdi mdi-window-close"></i>');
        }
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Count Statistics: ' + err.status);
        }

        $('#agentsCount').html('<i class="mdi mdi-window-close"></i>');
        $('#billersCount').html('<i class="mdi mdi-window-close"></i>');
        $('#devicesCount').html('<i class="mdi mdi-window-close"></i>');
        $('#subscribersCount').html('<i class="mdi mdi-window-close"></i>');

      }
    )
  }

  getTransactionStatistics(){
    this.transLoading = true;
    this.accountsService.getTransactionsStatistics()
    .subscribe(
      data => {
        // console.log(data);
        this.statsTransactions = data;
        this.transLoading = false;
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Transaction Statistics: ' + err.status);
        }
        this.transLoading = false;
      }
    )
  }

  getCommissionStatistics(){
    this.commsLoading = true;
    this.accountsService.getCommissionsStatistics()
    .subscribe(
      data => {
        // console.log(data);
        this.Commissions = data;
        this.commsLoading = false;
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Commissions Statistics: ' + err.status);
        }
        this.commsLoading = false;
      }
    )
  }

  getTypes(){
    this.configService.getTransactionTypes()
    .subscribe(
      data => {
        // console.log(data);
        this.types = data.content;
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Transaction Types: ' + err.status);
        }
      }
    )
  }

  handleSelected(ele){
    var sele = ele.target.value;
    // console.log(sele);
    
    this.selected = sele;
    this.getTransactions();
  }

  getName(name){
    return name.replace(/_/g, " ");
  }

  getTransactions(){
    if(this.selected){
      this.loading = true;

      var searchParam = '?search=transactionType%3A' + this.selected;

      this.transactionsService.getDashboardTransactionsByType(searchParam)
      .subscribe(
        data => {
          // console.log(data);
          this.transactions = data.content;
          this.loading  = false;
        },
        err => {
          console.log(err);
          if(err.status == 0){
            this.toast.error('Failed to Connect.');
          }
          else{
            this.toast.error('Failed to get Transactions: ' + err.status);
          }
          this.loading  = false;
        }
      )
      
    }
  }

}
