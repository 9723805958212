import { AuditsService } from 'src/app/shared/services/audits.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TransactionsService } from 'src/app/shared/services/transactions.service';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-agent-transactions',
  templateUrl: './agent-transactions.component.html',
  styleUrls: ['./agent-transactions.component.scss']
})
export class AgentTransactionsComponent implements OnInit {

  loading: boolean;
  loading2: boolean;
  dateRange: string;
  transactions: any;
  transaction: any;
  searchQuery: string;
  agentId: number;

  config: any;
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  totalItems = 0;
  showin = 0;

  constructor(
    private toast: ToastrService,
    private transactionService: TransactionsService,
    private modalService: NgbModal,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private auditService: AuditsService
  ) { }

  ngOnInit(): void {
    this.activateRoute.params.subscribe(
      params => {
        this.agentId = params['id'];
      }
    );
  }

  handleDuration(){
    if(!$('#startDate').val()){
      $('#startDate').css({'border-color':'red'});
    }
    else if(!$('#endDate').val()){
      $('#endDate').css({'border-color':'red'});
    }
    else{
      $('#startDate').css({'border-color':'#ced4da'});
      $('#endDate').css({'border-color':'#ced4da'});

      var duran = {
        startDate: $('#startDate').val(),
        endDate: $('#endDate').val()
      }

      this.dateRange = $.param(duran);

      this.loading = true;
      // console.log(this.params);
      this.getTransactions();
      
    }
  }

  handlePageChange(event) {
    this.page = event;
    this.getTransactions();
  }

  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getTransactions();
  }

  getTransactions(){
      this.transactionService.getAgentPaginatedDateRangeTransactions(this.agentId, this.page - 1, this.pageSize, this.dateRange)
      .subscribe(
        data => {
          // console.log(data);
          this.config = {
            itemsPerPage: this.pageSize,
            currentPage: data.pageable.pageNumber + 1,
            totalItems: data.totalElements,
            totalPages: data.totalPages
          };   
  
          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }
          
          this.transactions = data.content;
          this.loading = false;
        },
        err => {
          console.log(err);
          if(err.status == 0){
            this.toast.error('Failed to Connect.');
          }
          else{
            this.toast.error('Failed to get Transactions: ' + err.status);
          }
          this.loading = false;
        }
      );
  }

  loadInfo(ref){
    if(ref){
      this.transactions.forEach(r => {
        if(r.id == ref){
          this.transaction = r;
        }
      });
    }

    // console.log(this.transaction);
    
  }

  goBack(){
    this.router.navigate(['/admin/new-merchants/info/' + this.agentId]);
  }

  openModal( modal ) {
    this.modalService.open( modal );
  }

  getName(name){
    if(name){
      return name.replace(/_/g, " ");
    }

    return '';
  }

  exportReport(typ){
    if(!$('#startDate').val()){
      $('#startDate').css({'border-color':'red'});
      return false;
    }
    else if(!$('#endDate').val()){
      $('#endDate').css({'border-color':'red'});
      return false;
    }
    else{
      $('#startDate').css({'border-color':'#ced4da'});
      $('#endDate').css({'border-color':'#ced4da'});

      var duran = {
        reportFormat: typ,
        startDate: $('#startDate').val(),
        endDate: $('#endDate').val()
      }

      this.dateRange = $.param(duran);
    }

    this.loading2 = true;
    this.auditService.exportMerchantTransactions(this.dateRange, typ)
    .subscribe(
      resp => {
        this.loading2 = false;
        fileSaver.saveAs(resp, 'merchant transactions from ' + duran.startDate + ' to ' + duran.endDate + '.' + typ.toLowerCase());
        // const url = window.URL.createObjectURL(resp);
			  // window.open(url);
      },
      err => {
        console.log(err);
        // this.toast.error('An Error Occured. Please Try Again.!');
        this.loading2 = false;
      }
    )
  }

}
