import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TransactionsService } from 'src/app/shared/services/transactions.service';

@Component({
  selector: 'app-cards-linking',
  templateUrl: './cards-linking.component.html',
  styleUrls: ['./cards-linking.component.scss']
})
export class CardsLinkingComponent implements OnInit {

  loading: boolean = true;
  selectedType: string;

  linked: any;
  unlinked: any;

  approveUnlinkLoader: boolean;
  approveLinkLoader: boolean;
  actionId: any;

  config: any;
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  totalItems = 0;
  showin = 0;

  config2: any;
  page2 = 1;
  count2 = 0;
  pageSize2 = 10;
  pageSizes2 = [10, 25, 50, 100];
  totalItems2 = 0;
  showin2 = 0;

  constructor(
    private modalService: NgbModal,
    private toast: ToastrService,
    private transactionService: TransactionsService
  ) { }

  ngOnInit(): void {
    this.getLinked();
    this.getUnlinked();
  }

  getLinked(){
    this.transactionService.getLinkedCardsRequests(0, 500)
    .subscribe(
      data => {
        // console.log(data);
        this.linked = data.content;
        this.loading = false;
      },
      (err) => {
        console.log(err);
        if ((err.status == 0)) {
          this.toast.error("Failed to Connect.");
        } else {
          this.toast.error("Failed to get Linked: " + err.status);
        }
        this.loading = false;
      }
    )
  }

  getUnlinked(){
    this.transactionService.getUnLinkedCardsRequests(0, 500)
    .subscribe(
      data => {
        // console.log(data);
        this.unlinked = data.content;
        this.loading = false;
      },
      (err) => {
        console.log(err);
        if ((err.status == 0)) {
          this.toast.error("Failed to Connect.");
        } else {
          this.toast.error("Failed to get Unlinked: " + err.status);
        }
        this.loading = false;
      }
    )
  }

  selectType(typ){
    this.selectedType = typ;
  }

  storeActionId(ref, modal){
    this.actionId = ref;

    this.openModal(modal);
  }

  approveUnlink(){
    this.approveUnlinkLoader = true;
    this.transactionService.ApproveUnlinkSubscriberCard(this.actionId)
    .subscribe(
      resp => {
        // console.log(resp);
        this.approveUnlinkLoader = false;
        this.modalService.dismissAll();
        this.loading = true;
        this.getUnlinked();
      },
      err => {
        console.log(err);
        if ((err.status == 0)) {
          this.toast.error("Failed to Connect.");
        } else {
          this.toast.error("Failed to approve Unlink: " + err.status);
        }
        this.approveUnlinkLoader = false;
      }
    )
  }

  approveLink(){
    this.approveLinkLoader = true;
    this.transactionService.ApproveLinkSubscriberCard(this.actionId)
    .subscribe(
      resp => {
        // console.log(resp);
        this.approveLinkLoader = false;
        this.modalService.dismissAll();
        this.loading = true;
        this.getLinked();
      },
      err => {
        console.log(err);
        if ((err.status == 0)) {
          this.toast.error("Failed to Connect.");
        } else {
          this.toast.error("Failed to approve Link: " + err.status);
        }
        this.approveLinkLoader = false;
      }
    )
  }

  openModal(modal) {
    this.modalService.open(modal);
  }

}
