import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const baseUrl = environment.APIurl + '/akupay-subscriber-service/api/v1';

@Injectable({
  providedIn: 'root'
})
export class SubscribersService {

  constructor(
    private http: HttpClient
  ) { }

  getAllSubscribers(): Observable<any> {
    return this.http.get(baseUrl + '/subscribers/0/1000');
  }

  getPaginatedSubscribers(page, size): Observable<any> {
    return this.http.get(baseUrl + '/subscribers/' + page + '/' + size);
  }

  getSubscriberById(id): Observable<any> {
    return this.http.get(baseUrl + '/subscriber/'+ id);
  }

  getSubscriberByMobile(mobile): Observable<any> {
    return this.http.get(baseUrl + '/subscriber-mobile/'+ mobile);
  }

  resetSubscriber(num): Observable<any> {
    return this.http.put(baseUrl + '/subscriber-reset/' + num, null);
  }

  updateSubscriber(data): Observable<any> {
    return this.http.put(baseUrl + '/subscriber', data);
  }

  createSubscriber(data): Observable<any> {
    return this.http.post(baseUrl + '/subscriber', data);
  }

  getAllPendingSubscribers(): Observable<any> {
    return this.http.get(baseUrl + '/subscribers-pending/0/1000');
  }

  getPaginatedPendingSubscribers(page, size): Observable<any> {
    return this.http.get(baseUrl + '/subscribers-pending/' + page + '/' + size);
  }

  getAllDeclinedSubscribers(): Observable<any> {
    return this.http.get(baseUrl + '/subscribers-decline/0/1000');
  }

  getPaginatedDeclinedSubscribers(page, size): Observable<any> {
    return this.http.get(baseUrl + '/subscribers-decline/' + page + '/' + size);
  }

  approveSubscriber(ref): Observable<any> {
    return this.http.put(baseUrl + '/subscriber-approve/' + ref, null);
  }

  declineSubscriber(ref, reason): Observable<any> {
    return this.http.put(baseUrl + `/subscriber-decline/${ref}/${reason}`, null);
  }

} 
