import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from 'src/app/shared/services/users.service';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit {

  roles: any;
  loading: boolean = true;
  load: boolean = true;
  loadingPerms: boolean;
  selectedRole: number;
  assPerms: any;
  unAssPerms: any;
  addloader: boolean;
  removeloader: boolean;

  addForm: FormGroup;
  removeForm: FormGroup;

  constructor(
    private toast: ToastrService,
    private usersService: UsersService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.getRoles();   
    
    this.addForm = this.fb.group({
      authoritiesId: [
        0
      ],
      roleId: 0
    });
    this.removeForm = this.fb.group({
      authoritiesId: [
        0
      ],
      roleId: 0
    });
  }

  getRoles(){
    this.usersService.getAllRoles()
    .subscribe(
      data => {
        // console.log(data);
        this.roles = data;
        this.loading = false;
      },
      err => {
        console.log(err);
        this.toast.error('An Error Occured. Please Try Again.!!');
      }
    )
  }

  selectRole(rid){
    if(rid){
      this.loadingPerms = true;
      this.selectedRole = rid;

      this.addForm = this.fb.group({
        authoritiesId: [
          0
        ],
        roleId: rid
      });
      this.removeForm = this.fb.group({
        authoritiesId: [
          0
        ],
        roleId: rid
      });

      this.getAssPerms();
      this.getUnAssPerms();
    }
  }

  getAssPerms(){
    this.usersService.getAssignedPermissions(this.selectedRole)
    .subscribe(
      data => {
        // console.log(data);
        this.assPerms = data;
        this.loadingPerms = false;
      },
      err => {
        console.log(err);
        this.toast.error('An Error Occured. Please Try Again.!!');
      }
    )
  }

  getUnAssPerms(){
    this.usersService.getUnassignedPermissions(this.selectedRole)
    .subscribe(
      data => {
        // console.log(data);
        this.unAssPerms = data;
        this.loadingPerms = false;
        this.load = false;
      },
      err => {
        console.log(err);
        this.toast.error('An Error Occured. Please Try Again.!!');
      }
    )
  }

  addPermission(form: FormGroup){
    // console.log(form.value);
    
    if(form.valid){
      this.addloader = true;
      this.usersService.assignPermission(form.value)
      .subscribe(
        resp => {
          // console.log(resp);
          this.toast.success('Permission Added.');
          this.loadingPerms = true;
          this.addloader = false;
          this.getAssPerms();
          this.getUnAssPerms();
        }
      )
    }
  }

  removePermission(form: FormGroup){
    // console.log(form.value);
    
    if(form.valid){
      this.removeloader = true;
      this.usersService.unassignPermission(form.value)
      .subscribe(
        resp => {
          // console.log(resp);
          this.toast.success('Permission Removed.');
          this.loadingPerms = true;
          this.removeloader = false;
          this.getAssPerms();
          this.getUnAssPerms();
        }
      )
    }
  }

  public removeUnderscore(str: string) {
    return str.replace(/_/g, " ");
  }

}
