import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const baseUrl = environment.APIurl + '/akupay-system-service/api/v1';

@Injectable({
  providedIn: 'root'
})
export class ConfigsService {

  constructor(
    private http: HttpClient
  ) { }

  // agent profiles
  getAgentProfiles(): Observable<any> {
    return this.http.get(baseUrl + '/agentprofile/0/100');
  }

  getAgentProfile(ref): Observable<any> {
    return this.http.get(baseUrl + '/agentprofile/' + ref);
  } 

  createAgentProfile(data): Observable<any>{
    return this.http.post(baseUrl + '/agentprofile', data);
  }

  updateAgentProfile(data): Observable<any>{
    return this.http.put(baseUrl + '/agentprofile', data);
  }

  // subscribers profiles
  getSubscriberProfiles(): Observable<any> {
    return this.http.get(baseUrl + '/subscriberprofiles/0/100');
  }

  getSubscribertProfile(ref): Observable<any> {
    return this.http.get(baseUrl + '/subscriberprofile/' + ref);
  } 

  createSubscriberProfile(data): Observable<any>{
    return this.http.post(baseUrl + '/subscriberprofile', data);
  }

  updateSubscriberProfile(data): Observable<any>{
    return this.http.put(baseUrl + '/subscriberprofile', data);
  }

  // agent limits
  getAgentLimits(prof): Observable<any> {
    return this.http.get(baseUrl + '/agenttransactionlimit/' + prof);
  }

  getAgentLimit(ref): Observable<any> {
    return this.http.get(baseUrl + '/agenttransactionlimit/' + ref);
  } 

  createAgentLimit(data): Observable<any>{
    return this.http.post(baseUrl + '/agenttransactionlimit', data);
  }

  updateAgentLimit(data): Observable<any>{
    return this.http.put(baseUrl + '/agenttransactionlimit', data);
  }

  // subscriber limits
  getSubscriberLimits(prof): Observable<any> {
    return this.http.get(baseUrl + '/subscribertransactionlimit/' + prof);
  }

  getSubscriberLimit(ref): Observable<any> {
    return this.http.get(baseUrl + '/subscribertransactionlimit/' + ref);
  } 

  createSubscriberLimit(data): Observable<any>{
    return this.http.post(baseUrl + '/subscribertransactionlimit', data);
  }

  updateSubscriberLimit(data): Observable<any>{
    return this.http.put(baseUrl + '/subscribertransactionlimit', data);
  }

  // agent velocity
  getAgentVelocities(): Observable<any> {
    return this.http.get(baseUrl + '/agentvelocity/0/100');
  }

  getAgentVelocity(ref): Observable<any> {
    return this.http.get(baseUrl + '/agentvelocity/' + ref);
  } 

  createAgentVelocity(data): Observable<any>{
    return this.http.post(baseUrl + '/agentvelocity', data);
  }

  updateAgentVelocity(data): Observable<any>{
    return this.http.put(baseUrl + '/agentvelocity', data);
  }

  // subscribers velocity
  getSubscriberVelocities(): Observable<any> {
    return this.http.get(baseUrl + '/subscribervelocity/0/100');
  }

  getSubscriberVelocity(ref): Observable<any> {
    return this.http.get(baseUrl + '/subscribervelocity/' + ref);
  } 

  createSubscriberVelocity(data): Observable<any>{
    return this.http.post(baseUrl + '/subscribervelocity', data);
  }

  updateSubscriberVelocity(data): Observable<any>{
    return this.http.put(baseUrl + '/subscribervelocity', data);
  }

  // agent fees
  getAgentProfileFees(prof): Observable<any> {
    return this.http.get(baseUrl + '/agenttransactionfee/' + prof);
  }

  getAgentFees(prof, transtype): Observable<any> {
    return this.http.get(baseUrl + '/agenttransactionfee/' + prof + '/' + transtype);
  }

  getAgentFee(ref): Observable<any> {
    return this.http.get(baseUrl + '/agenttransactionfee/' + ref);
  } 

  createAgentFee(data): Observable<any>{
    return this.http.post(baseUrl + '/agenttransactionfee', data);
  }

  updateAgentFee(data): Observable<any>{
    return this.http.put(baseUrl + '/agenttransactionfee', data);
  }

  // subscriber limits
  getSubscriberProfileFees(prof): Observable<any> {
    return this.http.get(baseUrl + '/subscribertransactionfee/' + prof);
  }

  getSubscriberFees(prof, transtype): Observable<any> {
    return this.http.get(baseUrl + '/subscribertransactionfee/' + prof + '/' + transtype);
  }

  getSubscriberFee(ref): Observable<any> {
    return this.http.get(baseUrl + '/subscribertransactionfee/' + ref);
  } 

  createSubscriberFee(data): Observable<any>{
    return this.http.post(baseUrl + '/subscribertransactionfee', data);
  }

  updateSubscriberFee(data): Observable<any>{
    return this.http.put(baseUrl + '/subscribertransactionfee', data);
  }

  // transaction types
  getTransactionTypes(): Observable<any> {
    return this.http.get(baseUrl + '/transactiontype/0/100');
  }

  getTransactionType(ref): Observable<any> {
    return this.http.get(baseUrl + '/transactiontype/' + ref);
  } 

  createTransactionType(data): Observable<any>{
    return this.http.post(baseUrl + '/transactiontype', data);
  }

  updateTransactionType(data): Observable<any>{
    return this.http.put(baseUrl + '/transactiontype', data);
  }

  // agent commissions
  getAgentCommissions(prof): Observable<any> {
    return this.http.get(baseUrl + '/agentcommission/' + prof);
  }

  getAgentCommission(ref): Observable<any> {
    return this.http.get(baseUrl + '/agentcommission/' + ref);
  } 

  createAgentCommission(data): Observable<any>{
    return this.http.post(baseUrl + '/agentcommission', data);
  }

  updateAgentCommission(data): Observable<any>{
    return this.http.put(baseUrl + '/agentcommission', data);
  }

}
