import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ResetTokenComponent } from './auth/reset-token/reset-token.component';
import { SigninComponent } from './auth/signin/signin.component';
import { AuthGuard } from './shared/auth.guard';


const routes: Routes = [
  { path: '', component: SigninComponent },
  { path: 'login', component: SigninComponent },
  { path: 'resetPassword', component: ResetPasswordComponent },
  { path: 'resetPassword/changePassword', component: ResetTokenComponent },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard] },
  { path: '**', redirectTo: 'error-pages/404', pathMatch: 'full' },
  { path: 'error-pages', loadChildren: () => import('./error-pages/error-pages.module').then(m => m.ErrorPagesModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
