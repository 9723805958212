import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
import { UsersService } from 'src/app/shared/services/users.service';
import { environment } from 'src/environments/environment';
declare var $: any;

const baseUrl = environment.APIurl + '/user-manager';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {

  usersForm: FormGroup;
  loader: boolean;
  roles: any;

  constructor(
    private fb: FormBuilder,
    private userService: UsersService,
    private router: Router,
    private toast: ToastrService,
    public permService: PermissionsService
  ) {
    if(!this.permService.hasAccessAuthority('CREATE_USER')){
      toast.warning('Access Denied');
      router.navigateByUrl('landing-page');
    }
   }

  ngOnInit(): void {
    this.getRoles();

    this.usersForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      roleId: ['', Validators.required],
      username: ['', Validators.required]
    });
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  checkEmail(ele){
    // console.log(ele);

    var typed = ele.target.value;

    if(this.validateEmail(typed)){
      $(ele.target).removeClass('is-invalid');
      $(ele.target).addClass('is-valid');
    }
    else{
      $(ele.target).removeClass('is-valid');
      $(ele.target).addClass('is-invalid');
    }
  }

  addUser(form: FormGroup){
    // console.log(form.value);
    
    if(!this.validateEmail(form.value.email)){
      $('#emailErr').html('Enter valid email.');
      return false;
    }
    else{
      $('#emailErr').html('');
    }

    if(form.valid){
      this.loader = true;
      // console.log(form.value);

      this.userService.createUser(form.value)
      .subscribe(
        response => {
          // console.log(response);
          this.loader = false;
          this.toast.success("User Created");
          this.router.navigate(['/admin/users/all']);
        },
        error => {
          this.loader = false;
          console.error(error);
          if(error.error.message){
            this.toast.error(error.error.message);
          }
          else{
            this.toast.error('An Error Occured. Please Try Again.!!!');
          }
        }
      );

    }
    else{
      this.toast.warning('Missing required Info.');
    }   
  }

  getName(name){
    if(name){
      return name.replace(/_/g, " ");
    }

    return '';
  }

  getRoles(){
    this.userService.getAllRoles()
    .subscribe(
      data => {
        // console.log(data);
        this.roles = data;
      },
      err => {
        console.log(err);
        this.toast.error('An Error Occured. Please Try Again.!!');
      }
    )
  }

}
