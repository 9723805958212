import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AgentCommissionsComponent } from './agent-commissions/agent-commissions.component';
import { AgentFeesComponent } from './agent-fees/agent-fees.component';
import { AgentLimitsComponent } from './agent-limits/agent-limits.component';
import { AgentProfileComponent } from './agent-profile/agent-profile.component';
import { AgentVelocitiesComponent } from './agent-velocities/agent-velocities.component';
import { SubscriberFeesComponent } from './subscriber-fees/subscriber-fees.component';
import { SubscriberLimitsComponent } from './subscriber-limits/subscriber-limits.component';
import { SubscriberProfileComponent } from './subscriber-profile/subscriber-profile.component';
import { SubscriberVelocitiesComponent } from './subscriber-velocities/subscriber-velocities.component';
import { TransactionTypesComponent } from './transaction-types/transaction-types.component';

const routes: Routes = [
  { path: 'agent-profile', component: AgentProfileComponent },
  { path: 'agent-fees', component: AgentFeesComponent },
  { path: 'agent-limits', component: AgentLimitsComponent },
  { path: 'agent-velocity', component: AgentVelocitiesComponent },
  { path: 'subscriber-profile', component: SubscriberProfileComponent },
  { path: 'subscriber-fees', component: SubscriberFeesComponent },
  { path: 'subscriber-limits', component: SubscriberLimitsComponent },
  { path: 'subscriber-velocity', component: SubscriberVelocitiesComponent },
  { path: 'agent-commission', component: AgentCommissionsComponent },
  { path: 'trans-types', component: TransactionTypesComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConfigsRoutingModule { }
