import { Injectable } from '@angular/core';
// import * as CryptoJS from 'crypto-js';

const SECRET_KEY = 'adminremit263@2020';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor() { }

    encrypt(data) {
        // data = CryptoJS.AES.encrypt(data, SECRET_KEY);
        let encData = data.toString();
        return encData;
    }

    decrypt(data) {
        if(data){
            // data = CryptoJS.AES.decrypt(data, SECRET_KEY);
            // let decData = data.toString(CryptoJS.enc.Utf8);
            return data;
        }
    }
}
 