import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuditTrailsComponent } from './audit-trails/audit-trails.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LandingComponent } from './landing/landing.component';

const routes: Routes = [
  { path: 'home', component: DashboardComponent },
  { path: 'landing-page', component: LandingComponent },
  { path: 'system-trails', component: AuditTrailsComponent },
  { path: 'merchants', loadChildren: () => import('./agents/agents.module').then(m => m.AgentsModule) },
  { path: 'new-merchants', loadChildren: () => import('./merchants/merchants.module').then(m => m.MerchantsModule) },
  { path: 'accounting', loadChildren: () => import('./accounting/accounting.module').then(m => m.AccountingModule) },
  { path: 'configs', loadChildren: () => import('./configs/configs.module').then(m => m.ConfigsModule) },
  { path: 'subscribers', loadChildren: () => import('./subscribers/subscribers.module').then(m => m.SubscribersModule) },
  { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
  { path: 'disbursements', loadChildren: () => import('./disbursements/disbursements.module').then(m => m.DisbursementsModule) },
  { path: 'billers', loadChildren: () => import('./billers/billers.module').then(m => m.BillersModule) },
  { path: 'banks', loadChildren: () => import('./banks/banks.module').then(m => m.BanksModule) },
  { path: 'devices', loadChildren: () => import('./devices/devices.module').then(m => m.DevicesModule) },
  { path: 'operators', loadChildren: () => import('./operators/operators.module').then(m => m.OperatorsModule) },
  { path: 'transactions', loadChildren: () => import('./transactions/transactions.module').then(m => m.TransactionsModule) },
  { path: 'loans', loadChildren: async () => await(await (import('./loans/loans.module'))).LoansModule},
  { path: 'adjustments', loadChildren: async () => await(await (import('./adjustments/adjustments.module'))).AdjustmentsModule},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
