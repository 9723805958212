import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AccountingService } from 'src/app/shared/services/accounting.service';

@Component({
  selector: 'app-settlement',
  templateUrl: './settlement.component.html',
  styleUrls: ['./settlement.component.scss']
})
export class SettlementComponent implements OnInit {

  loading: boolean;
  dateRange: string;
  report: any;
  transaction: any;

  config: any;
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  totalItems = 0;
  showin = 0;

  constructor(
    private accountingService: AccountingService,
    private toast: ToastrService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }

  handleDuration(){
    if(!$('#startDate').val()){
      $('#startDate').css({'border-color':'red'});
    }
    else if(!$('#endDate').val()){
      $('#endDate').css({'border-color':'red'});
    }
    else{
      $('#startDate').css({'border-color':'#ced4da'});
      $('#endDate').css({'border-color':'#ced4da'});

      var duran = {
        startDate: $('#startDate').val(),
        endDate: $('#endDate').val()
      }

      this.dateRange = $.param(duran);

      this.loading = true;
      // console.log(this.params);
      this.getReport();
      
    }
  }

  handlePageChange(event) {
    this.page = event;
    this.getReport();
  }

  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getReport();
  }

  getReport(){
    this.accountingService.getPaginatedDateRangeSettlementReport(this.page - 1, this.pageSize, this.dateRange)
      .subscribe(
        data => {
          // console.log(data);
          this.config = {
            itemsPerPage: this.pageSize,
            currentPage: data.pageable.pageNumber + 1,
            totalItems: data.totalElements,
            totalPages: data.totalPages
          };   
  
          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }
          
          this.report = data.content;
          this.loading = false;
        },
        err => {
          console.log(err);
          if(err.status == 0){
            this.toast.error('Failed to Connect.');
          }
          else{
            this.toast.error('Failed to get Report: ' + err.status);
          }
          this.loading = false;
        }
      );
  }

  loadInfo(ref){
    if(ref){
      this.report.forEach(r => {
        if(r.id == ref){
          this.transaction = r;
        }
      });
    }
    
  }

  openModal( modal ) {
    this.modalService.open( modal );
  }

  getName(name){
    if(name){
      return name.replace(/_/g, " ");
    }

    return '';
  }

}
