import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const baseUrl = environment.APIurl + '/akupay-biller-service/api/v1';


@Injectable({
  providedIn: 'root'
})
export class BillersService {

  constructor(
    private http: HttpClient
  ) { }

  // billers
  getAllBillers(): Observable<any> {
    return this.http.get(baseUrl + '/biller/0/1000');
  }

  getPaginatedBillers(page, size): Observable<any> {
    return this.http.get(baseUrl + '/biller/' + page + '/' + size);
  }

  getBillerById(id): Observable<any> {
    return this.http.get(`${baseUrl}/biller/${id}`);
  }

  createBiller(data): Observable<any> {
    return this.http.post(baseUrl + '/biller', data);
  }

  updateBiller(data): Observable<any> {
    return this.http.put(`${baseUrl}/biller`, data);
  }

  // products
  getPaginatedProducts(biller, page, size): Observable<any> {
    return this.http.get(baseUrl + '/product/' + biller + '/' + page + '/' + size);
  }

  getProductById(id): Observable<any> {
    return this.http.get(`${baseUrl}/product/${id}`);
  }

  createProduct(data): Observable<any> {
    return this.http.post(baseUrl + '/product', data);
  }

  updateProduct(data): Observable<any> {
    return this.http.put(`${baseUrl}/product`, data);
  }

}
