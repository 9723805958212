import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AgentInfoComponent } from './agent-info/agent-info.component';
import { AgentTransactionsComponent } from './agent-transactions/agent-transactions.component';
import { AllAgentsComponent } from './all-agents/all-agents.component';
import { NewAgentComponent } from './new-agent/new-agent.component';
import { UpdateAgentComponent } from './update-agent/update-agent.component';

const routes: Routes = [
  { path: 'all', component: AllAgentsComponent },
  { path: 'new', component: NewAgentComponent },
  { path: 'info/:id', component: AgentInfoComponent },
  { path: 'update/:id', component: UpdateAgentComponent },
  { path: 'transactions/:id', component: AgentTransactionsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AgentsRoutingModule { }
