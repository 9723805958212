import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountsStatementComponent } from './accounts-statement/accounts-statement.component';
import { BalancesSummaryComponent } from './balances-summary/balances-summary.component';
import { DailyTransactionsComponent } from './daily-transactions/daily-transactions.component';
import { EvalueStatementComponent } from './evalue-statement/evalue-statement.component';
import { ExceptionReportComponent } from './exception-report/exception-report.component';
import { FailedTransactionsComponent } from './failed-transactions/failed-transactions.component';
import { FeesReportComponent } from './fees-report/fees-report.component';
import { MonthlyTransactionsComponent } from './monthly-transactions/monthly-transactions.component';
import { ReportBillPaymentComponent } from './report-bill-payment/report-bill-payment.component';
import { ReportT24Component } from './report-t24/report-t24.component';
import { SettlementComponent } from './settlement/settlement.component';
import { TaxReportComponent } from './tax-report/tax-report.component';

const routes: Routes = [
  { path: 'statementOfAccountsP2P', component: AccountsStatementComponent },
  { path: 'summaryOfBalances', component: BalancesSummaryComponent },
  { path: 'feesReport', component: FeesReportComponent },
  { path: 'taxReport', component: TaxReportComponent },
  { path: 'settlement', component: SettlementComponent },
  { path: 'failedTransactions', component: FailedTransactionsComponent },
  { path: 'exceptionReport', component: ExceptionReportComponent },
  { path: 'evalueStatement', component: EvalueStatementComponent },
  { path: 'dailyTransactions', component: DailyTransactionsComponent },
  { path: 'monthlyTransactions', component: MonthlyTransactionsComponent },
  { path: 't24-report', component: ReportT24Component },
  { path: 'billPayment', component: ReportBillPaymentComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountingRoutingModule { }
