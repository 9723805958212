import { AuditsService } from 'src/app/shared/services/audits.service';
import { FileService } from 'src/app/shared/services/file.service';
import { environment } from './../../../../environments/environment';
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { AgentsService } from "src/app/shared/services/agents.service";
import { ConfigsService } from "src/app/shared/services/configs.service";
import { SubscribersService } from "src/app/shared/services/subscribers.service";
import { TransactionsService } from "src/app/shared/services/transactions.service";
import * as fileSaver from 'file-saver';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
declare var $: any;

@Component({
  selector: "app-subscriber-info",
  templateUrl: "./subscriber-info.component.html",
  styleUrls: ["./subscriber-info.component.scss"],
})
export class SubscriberInfoComponent implements OnInit {
  subscriber: any;
  subscriberId: any;
  loading: boolean = true;
  loading2: boolean;
  transloading: boolean;
  resetLoader: boolean;
  updateLoader: boolean;
  balance: number;
  subsForm: FormGroup;
  profiles: any;
  banks: any;
  bankInfo: any;
  subscriberForm: any;

  activateLoader: boolean;
  deactivateLoader: boolean;

  linkForm: FormGroup;
  cardForm: FormGroup;
  linkLoader: boolean;
  linkCardLoader: boolean;
  unlinkLoader: boolean;
  unlinkCardLoader: boolean;
  approveLinkCardLoader: boolean;
  approveLinkLoader: boolean;
  approveUnlinkLoader: boolean;
  approveUnlinkCardLoader: boolean;

  dateRange: string;
  transactions: any;
  transaction: any;
  cards: any;

  config: any;
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  totalItems = 0;
  showin = 0;

  baseUrl: string;
  profPic: any;
  nidPic: any;

  editForm: FormGroup;
  editLoader: boolean;

  constructor(
    private subscriberService: SubscribersService,
    private toast: ToastrService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private transactionService: TransactionsService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private configService: ConfigsService,
    private agentService: AgentsService,
    private fileService: FileService,
    private auditService: AuditsService,
    public permService: PermissionsService
  ) {}

  ngOnInit(): void {
    this.activateRoute.params.subscribe((params) => {
      this.subscriberId = params["id"];
      this.getSubscriber();
    });

    this.subsForm = this.fb.group({
      id: 0
    });

    this.getSubscriberProfiles();

    this.linkForm = this.fb.group({
      subscriberMobile: ['', Validators.required],
      bank: ['', Validators.required],
      bankCode: ['', Validators.required],
      bankAccount: ['', Validators.required],
      accountName: ['', Validators.required]
    });

    this.cardForm = this.fb.group({
      mobile: [''],
      cardNumber: ["", Validators.required],
      expiryDate: ["", Validators.required]
    });

    this.getBanks();

    this.subscriberForm = this.fb.group({
      id: 0,
    });

    this.baseUrl = environment.APIurl + '/akupay-file-storage-service/api/v1/files/download/';

    this.editForm = this.fb.group({
      id: 0
    });
  }

  getBanks(){
    this.agentService.getPaginatedBanks(0, 500)
    .subscribe(
      data => {
        // console.log(data);
        this.banks = data.content;
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Banks: ' + err.status);
        }
      }
    )
  }

  handleBankSelection(){
    let bank = this.linkForm.value.bank;

    if(bank){
      this.banks.forEach(r => {
        if(r.name == bank){
          this.linkForm.patchValue({
            bank: bank,
            subscriberMobile: this.subscriber.mobile,
            bankCode: r.code
          });
        }
      });
    }

    // console.log(this.linkForm.value);
    
  }

  linkBank(form: FormGroup){
    // console.log(form.value);
    
    if(form.valid){
      this.linkLoader = true;
      this.transactionService.linkSubscriberBankAccount(form.value)
      .subscribe(
        resp => {
          // console.log(resp);
          this.linkLoader = false;
          this.modalService.dismissAll();
          this.loading = true;
          this.getSubscriber();
        },
        err => {
          console.log(err);
          if ((err.status == 0)) {
            this.toast.error("Failed to Connect.");
          } else {
            this.toast.error("Failed to get Link Bank: " + err.status);
          }
          this.linkLoader = false;
        }
      )
    }
    else{
      this.toast.warning("Missing Required Info.");
    }
  }

  approveLink(){
    this.approveLinkLoader = true;
    this.transactionService.ApproveLinkSubscriberBankAccount(this.subscriber.id)
    .subscribe(
      resp => {
        console.log(resp);
        this.approveLinkLoader = false;
        this.modalService.dismissAll();
        this.loading = true;
        this.getSubscriber();
      },
      err => {
        console.log(err);
        if ((err.status == 0)) {
          this.toast.error("Failed to Connect.");
        } else {
          this.toast.error("Failed to approve Link: " + err.status);
        }
        this.approveLinkLoader = false;
      }
    )
  }

  unlinkBank(){
    this.unlinkLoader = true;
    this.transactionService.unlinkSubscriberBankAccount(this.subscriber.id)
    .subscribe(
      resp => {
        console.log(resp);
        this.unlinkLoader = false;
        this.modalService.dismissAll();
        this.loading = true;
        this.getSubscriber();
      },
      err => {
        console.log(err);
        if ((err.status == 0)) {
          this.toast.error("Failed to Connect.");
        } else {
          this.toast.error("Failed to Unlink Bank: " + err.status);
        }
        this.unlinkLoader = false;
      }
    )
  }

  approveUnlink(){
    this.approveUnlinkLoader = true;
    this.transactionService.ApproveUnlinkSubscriberBankAccount(this.subscriber.id)
    .subscribe(
      resp => {
        console.log(resp);
        this.approveUnlinkLoader = false;
        this.modalService.dismissAll();
        this.loading = true;
        this.getSubscriber();
      },
      err => {
        console.log(err);
        if ((err.status == 0)) {
          this.toast.error("Failed to Connect.");
        } else {
          this.toast.error("Failed to approve Unlink: " + err.status);
        }
        this.approveUnlinkLoader = false;
      }
    )
  }

  getSubscriber() {
    this.subscriberService.getSubscriberById(this.subscriberId).subscribe(
      (data) => {
        // console.log(data);
        this.subscriber = data;
        this.loading = false;

        if(!data.subscriberPhoto){
          this.profPic = 'assets/images/faces/users.png';
        }

        if(!data.idPhoto){
          this.nidPic = 'assets/images/faces/id-card.png';
        }
        // this.getTransactions();
        this.getBalance();
        this.getBankDetails();
        this.getCardInfo();
      },
      (err) => {
        console.log(err);
        if ((err.status == 0)) {
          this.toast.error("Failed to Connect.");
        } else {
          this.toast.error("Failed to get Subscriber Info: " + err.status);
        }
        this.loading = false;
      }
    );
  }

  // getIdPic(ref){
  //   this.fileService.getSubscriberFile(ref)
  //   .subscribe(
  //     data => {
  //       console.log(data);
        
  //       this.nidPic = 'assets/images/faces/id-card.png';
  //     },
  //     err => {
  //       this.toast.error('Error getting ID Picture');
  //       console.log(err);
        
  //     }
  //   )
  // }

  // getProfPic(ref){
  //   this.fileService.getSubscriberDisplayPic(ref)
  //   .subscribe(
  //     data => {
  //       console.log(data);        

  //       this.profPic = 'assets/images/faces/users.png';
  //     },
  //     err => {
  //       this.toast.error('Error getting Profile Picture');
  //       console.log(err);
        
  //     }
  //   )
  // }

  getBankDetails(){ 
    this.transactionService.getSubscriberBankDetails(this.subscriberId).subscribe(
      (data) => {
        // console.log(data[0]);
        this.bankInfo = data[0];
      },
      (err) => {
        console.log(err);
        if ((err.status == 0)) {
          this.toast.error("Failed to Connect.");
        } else {
          this.toast.error("Failed to get Bank Info: " + err.status);
        }
      }
    );
  }

  getSubscriberProfiles(){
    this.configService.getSubscriberProfiles()
    .subscribe(
      data => {
        // console.log(data);
        this.profiles = data.content;
      },
      err => {
        console.log(err);
        if(err.status == 0){
          this.toast.error('Failed to Connect.');
        }
        else{
          this.toast.error('Failed to get Profiles: ' + err.status);
        }
      }
    )
  }

  getBalance(){
    this.transactionService.getSubscriberBalance(this.subscriber.mobile)
    .subscribe(
      data => {
        // console.log(data);
        this.balance = data.amount;
      },
      (err) => {
        console.log(err);
        if ((err.status == 0)) {
          this.toast.error("Failed to Connect.");
        } else {
          this.toast.error("Failed to get Subscriber Balance: " + err.status);
        }
        this.loading = false;
      }
    )
  }

  handleDuration() {
    if (!$("#startDate").val()) {
      $("#startDate").css({ "border-color": "red" });
    } else if (!$("#endDate").val()) {
      $("#endDate").css({ "border-color": "red" });
    } else {
      $("#startDate").css({ "border-color": "#ced4da" });
      $("#endDate").css({ "border-color": "#ced4da" });

      var duran = {
        startDate: $("#startDate").val(),
        endDate: $("#endDate").val(),
      };

      this.dateRange = $.param(duran);

      this.transloading = true;
      // console.log(this.params);
      this.getTransactions();
    }
  }

  handlePageChange(event) {
    this.page = event;
    this.getTransactions();
  }

  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getTransactions();
  }

  getTransactions() {
    this.transactionService
      .getSubscriberPaginatedDateRangeTransactions(
        this.subscriberId,
        this.page - 1,
        this.pageSize,
        this.dateRange
      )
      .subscribe(
        (data) => {
          // console.log(data);
          this.config = {
            itemsPerPage: this.pageSize,
            currentPage: data.pageable.pageNumber + 1,
            totalItems: data.totalElements,
            totalPages: data.totalPages,
          };

          if (this.page * this.pageSize > data.totalElements) {
            this.showin = data.totalElements;
          } else {
            this.showin = this.page * this.pageSize;
          }

          this.transactions = data.content;
          this.transloading = false;
        },
        (err) => {
          console.log(err);
          if ((err.status == 0)) {
            this.toast.error("Failed to Connect.");
          } else {
            this.toast.error("Failed to get Transactions: " + err.status);
          }
          this.transloading = false;
        }
      );
  }

  loadInfo(ref) {
    if (ref) {
      this.transactions.forEach((r) => {
        if (r.id == ref) {
          this.transaction = r;
        }
      });
    }

    // console.log(this.transaction);
  }

  resetSubscriber(){
    this.resetLoader = true;
    this.subscriberService.resetSubscriber(this.subscriber.mobile)
    .subscribe(
      resp => {
        console.log(resp);
        this.toast.success('Subscriber Pin Reset Success');
        this.resetLoader = false;
        this.modalService.dismissAll();
      },
      err => {
        console.log(err);
        if ((err.status == 0)) {
          this.toast.error("Failed to Connect.");
        } else {
          this.toast.error("Failed to get Reset Pin: " + err.status);
        }
        this.resetLoader = false;
      }
    )
  }

  loadSubscriberInfo(modal){
    this.subsForm = this.fb.group({
      id: this.subscriber.id,
      mobile: this.subscriber.mobile,
      email: this.subscriber.email,
      firstname: this.subscriber.firstname,
      lastname: this.subscriber.lastname,
      idNumber: this.subscriber.idNumber,
      registrationDate: this.subscriber.registrationDate,
      subscriberProfile: ['', Validators.required],
      status: this.subscriber.status
    });

    this.openModal(modal);
  }

  changeProfile(form: FormGroup){
    console.log(form.value);
    
    if(form.valid){
      this.updateLoader = true;
      this.subscriberService.updateSubscriber(form.value)
      .subscribe(
        resp => {
          console.log(resp);
          this.toast.success('Subscriber Change Profile Success');
          this.updateLoader = false;
          this.modalService.dismissAll();
        },
        err => {
          console.log(err);
          if ((err.status == 0)) {
            this.toast.error("Failed to Connect.");
          } else {
            this.toast.error("Failed to change profile: " + err.status);
          }
          this.updateLoader = false;
        }
      )
    }
    else{
      this.toast.warning("Missing Required Info.");
    }
  }

  openModal(modal) {
    this.modalService.open(modal);
  }

  showAll() {
    this.router.navigate(["/admin/subscribers/all"]);
  }

  getName(name){
    if(name){
      return name.replace(/_/g, " ");
    }

    return '';
  }

  exportReport(typ){
    if(!$('#startDate').val()){
      $('#startDate').css({'border-color':'red'});
      return false;
    }
    else if(!$('#endDate').val()){
      $('#endDate').css({'border-color':'red'});
      return false;
    }
    else{
      $('#startDate').css({'border-color':'#ced4da'});
      $('#endDate').css({'border-color':'#ced4da'});

      var duran = {
        reportFormat: typ,
        startDate: $('#startDate').val(),
        endDate: $('#endDate').val()
      }

      this.dateRange = $.param(duran);
    }

    this.loading2 = true;
    this.auditService.exportSubscriberTransactions(this.dateRange, typ)
    .subscribe(
      resp => {
        this.loading2 = false;
        fileSaver.saveAs(resp, 'subscriber transactions from ' + duran.startDate + ' to ' + duran.endDate + '.' + typ.toLowerCase());
      },
      err => {
        console.log(err);
        this.loading2 = false;
      }
    )
  }

  activateUser(modal){
    this.subscriberForm = this.fb.group({
      id: this.subscriber.id,
      mobile: this.subscriber.mobile,
      email:this.subscriber.email,
      firstname: this.subscriber.firstname,
      lastname: this.subscriber.lastname,
      idNumber: this.subscriber.idNumber,
      registrationDate: this.subscriber.registrationDate,
      // subscriberProfile: this.subscriber.,
      dateOfBirth: this.subscriber.dateOfBirth,
      gender: this.subscriber.gender,
      subscriberPhoto: this.subscriber.subscriberPhoto,
      idPhoto: this.subscriber.idPhoto,
      status: "ACTIVE"
    });

    this.openModal(modal);
  }

  deactivateUser(modal){
    this.subscriberForm = this.fb.group({
      id: this.subscriber.id,
      mobile: this.subscriber.mobile,
      email:this.subscriber.email,
      firstname: this.subscriber.firstname,
      lastname: this.subscriber.lastname,
      idNumber: this.subscriber.idNumber,
      registrationDate: this.subscriber.registrationDate,
      // subscriberProfile: this.subscriber.,
      dateOfBirth: this.subscriber.dateOfBirth,
      gender: this.subscriber.gender,
      subscriberPhoto: this.subscriber.subscriberPhoto,
      idPhoto: this.subscriber.idPhoto,
      status: "BLOCKED"
    });

    this.openModal(modal);
  }

  updateSubscriber(){
    // console.log(this.subscriberForm.value);
    
    this.activateLoader = true;
    this.deactivateLoader = true;

    this.subscriberService.updateSubscriber(this.subscriberForm.value)
    .subscribe(
      resp => {
        this.activateLoader = false;
        this.deactivateLoader = false;
        console.log(resp);

        this.toast.success('Subscriber Status changed.');
        this.modalService.dismissAll();
        this.getSubscriber();
      },
      err => {
        console.log(err);
        if ((err.status == 0)) {
          this.toast.error("Failed to Connect.");
        } else {
          this.toast.error("Failed to change status: " + err.status);
        }
        this.activateLoader = false;
        this.deactivateLoader = false;
    }
    )
  }

  print(){
    window.print();
  }

  linkCard(form: FormGroup){
    // console.log(form.value);
    form.value.mobile = this.subscriber.mobile
    if(form.valid){
      this.linkCardLoader = true;
      this.transactionService.linkSubscriberCard(form.value)
      .subscribe(
        resp => {
          // console.log(resp);
          this.linkCardLoader = false;
          this.modalService.dismissAll();
          this.loading = true;
          this.getSubscriber();
        },
        err => {
          console.log(err);
          if ((err.status == 0)) {
            this.toast.error("Failed to Connect.");
          } else {
            this.toast.error("Failed to Link Card: " + err.status);
          }
          this.linkCardLoader = false;
        }
      )
    }
    else{
      this.toast.warning("Missing Required Info.");
    }
  }

  unlinkCard(){
    this.unlinkCardLoader = true;
    this.transactionService.unlinkSubscriberCard(this.subscriber.mobile)
    .subscribe(
      resp => {
        console.log(resp);
        this.unlinkCardLoader = false;
        this.modalService.dismissAll();
        this.loading = true;
        this.getSubscriber();
      },
      err => {
        console.log(err);
        if ((err.status == 0)) {
          this.toast.error("Failed to Connect.");
        } else {
          this.toast.error("Failed to Unlink Card: " + err.status);
        }
        this.unlinkCardLoader = false;
      }
    )
  }

  getCardInfo(){
    this.transactionService.getSubscriberCards(this.subscriber.mobile)
    .subscribe(
      resp => {
        // console.log(resp);
        this.cards = resp;
      },
      err => {
        console.log(err);
        if ((err.status == 0)) {
          this.toast.error("Failed to Connect.");
        } else {
          this.toast.error("Failed to get Cards: " + err.status);
        }
      }
    )
  }

  edit(modal){
    this.editForm = this.fb.group({
      // agentName: [sd.agentName, Validators.required],
      city: [this.subscriber.city, Validators.required],
      dateOfBirth: [this.subscriber.dateOfBirth, Validators.required],
      email: [this.subscriber.email, Validators.required],
      firstname: [this.subscriber.firstname, Validators.required],
      gender: [this.subscriber.gender, Validators.required],
      id: [this.subscriber.id, Validators.required],
      idNumber: [this.subscriber.idNumber, Validators.required],
      idPhoto: [this.subscriber.idPhoto, Validators.required],
      lastname: [this.subscriber.lastname, Validators.required],
      mobile: [this.subscriber.mobile, Validators.required],
      // reason: [this.subscriber.reason],
      registrationDate: [this.subscriber.registrationDate, Validators.required],
      status: [this.subscriber.status, Validators.required],
      street: [this.subscriber.street, Validators.required],
      subscriberPhoto: [this.subscriber.subscriberPhoto, Validators.required],
      // subscriberProfile: [this.subscriber.subscriberProfile, Validators.required],
      suburb: [this.subscriber.suburb, Validators.required]
    });

    this.openModal(modal);
  }

  updateSubscriberOnEdit(form: FormGroup){
    // console.log(form.value);

    if(form.valid){
      this.subscriberService.updateSubscriber(form.value)
      .subscribe(
        resp => {
          this.editLoader = false;
          console.log(resp);

          this.toast.success('Subscriber Updated.');
          this.modalService.dismissAll();
        },
        err => {
          console.log(err);
          if ((err.status == 0)) {
            this.toast.error("Failed to Connect.");
          } else {
            this.toast.error("Failed to update: " + err.status);
          }
          this.editLoader = false;
        }
      )
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  checkEmail(ele){
    // console.log(ele);

    var typed = ele.target.value;

    if(this.validateEmail(typed)){
      $(ele.target).removeClass('is-invalid');
      $(ele.target).addClass('is-valid');
    }
    else{
      $(ele.target).removeClass('is-valid');
      $(ele.target).addClass('is-invalid');
    }
  }
}
