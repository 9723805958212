import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersRoutingModule } from './users-routing.module';
import { AllUsersComponent } from './all-users/all-users.component';
import { NewUserComponent } from './new-user/new-user.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PermissionsComponent } from './permissions/permissions.component';


@NgModule({
  declarations: [AllUsersComponent, NewUserComponent, ChangePasswordComponent, PermissionsComponent],
  imports: [
    CommonModule,
    UsersRoutingModule,
    ReactiveFormsModule,
    NgxPaginationModule
  ]
})
export class UsersModule { }
