import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdownConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
  public iconOnlyToggled = false;
  public sidebarToggled = false;

  username: string;
  
  constructor(
    config: NgbDropdownConfig,
    private authService: AuthService,
    private router: Router,
    private modalService: NgbModal,
    private toast: ToastrService
    ) {
    config.placement = 'bottom-right';
  }

  ngOnInit() {
    this.username = this.authService.getUserUsername();
  }

  // toggle sidebar in small devices
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  // toggle sidebar
  toggleSidebar() {
    let body = document.querySelector('body');
    if((!body.classList.contains('sidebar-toggle-display')) && (!body.classList.contains('sidebar-absolute'))) {
      this.iconOnlyToggled = !this.iconOnlyToggled;
      if(this.iconOnlyToggled) {
        body.classList.add('sidebar-icon-only');
      } else {
        body.classList.remove('sidebar-icon-only');
      }
    } else {
      this.sidebarToggled = !this.sidebarToggled;
      if(this.sidebarToggled) {
        body.classList.add('sidebar-hidden');
      } else {
        body.classList.remove('sidebar-hidden');
      }
    }
  }

  // toggle right sidebar
  // toggleRightSidebar() {
  //   document.querySelector('#right-sidebar').classList.toggle('open');
  // }

  //user logout
  logout(){
    this.authService.doLogout();
  }

  changePassword(){
    this.router.navigate(['/admin/users/changePassword']);
  }

  openModal(modal){
    this.modalService.open(modal);
  }

  updateRate(){
    var rt = $('#myRate').val();

    if(rt){
      $('#myRate').css('borderColor', '#ebedf2');
      this.modalService.dismissAll();
    }
    else{
      this.toast.warning('Enter Rate');
      $('#myRate').css('borderColor', 'red');
      return false;
    }
  }

}
