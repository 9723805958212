import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const fileUrl = environment.APIurl + '/akupay-file-storage-service/api/v1/files';
const baseUrl = environment.APIurl + '/akupay-registration-service/api/v1';
const fileUrl2 = environment.APIurl + '/akupay-disbursement-service/api/v1/files';
const baseUrl2 = environment.APIurl + '/akupay-disbursement-service/api/v1';

const fileStore = environment.APIurl + '/akupay-file-storage-service/api/v1/files';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private http: HttpClient
  ) { }

  // file handling
  uploadDocument(file: File): Observable<HttpEvent<any>> {

    const formData: FormData = new FormData();

    const uploadUrl = `${fileUrl}/upload`;

    formData.append('file', file);

    const uploadRequest = new HttpRequest('POST', uploadUrl, formData, {
      reportProgress: true,
      // responseType: 'json' 
    });

    return this.http.request(uploadRequest);
  }

  getFile(fname): Observable<any> {
    return this.http.get(fileUrl + '/download/' + fname, {responseType: 'blob'});
  }

  getSubscriberFile(fname): Observable<any> {
    return this.http.get(fileStore + '/download/' + fname, {responseType: 'blob'});
  }

  getSubscriberDisplayPic(fname): Observable<any> {
    return this.http.get(fileStore + '/download/' + fname);
  }

  // Bulk Registration
  initiateBulkReg(fname, profile, agent): Observable<any> {
    return this.http.post(baseUrl + '/bulk-registration/register/' + profile + '/' + fname + '/' + agent, null);
  }

  getPaginatedBatchRegistrationReport(batch, page, size): Observable<any> {
    return this.http.get(baseUrl + '/bulk-registration/registration/' + batch + '/' + page + '/' + size);
  }

  // Disbursements
  initiateDisbursement(fname, agent): Observable<any> {
    return this.http.post(baseUrl2 + '/bulk-disbursement/initiate/' + fname + '/' + agent, null);
  }

  getPaginatedBatchInitiatedDisbursementReport(batch, page, size): Observable<any> {
    return this.http.get(baseUrl2 + '/bulk-disbursement/initiated/' + batch + '/' + page + '/' + size);
  }

  getPaginatedBatchFailedDisbursementReport(batch, page, size): Observable<any> {
    return this.http.get(baseUrl2 + '/bulk-disbursement/failed/' + batch + '/' + page + '/' + size);
  }

  getPaginatedBatchValidatedDisbursementReport(batch, page, size): Observable<any> {
    return this.http.get(baseUrl2 + '/bulk-disbursement/validated/' + batch + '/' + page + '/' + size);
  }

  approveDisbursements(batch, data): Observable<any> {
    return this.http.put(baseUrl2 + '/bulk-disbursement/validate/' + batch, data);
  }

  uploadDocument2(file: File): Observable<HttpEvent<any>> {

    const formData: FormData = new FormData();

    const uploadUrl = `${fileUrl2}/upload`;

    formData.append('file', file);

    const uploadRequest = new HttpRequest('POST', uploadUrl, formData, {
      reportProgress: true,
      // responseType: 'json' 
    });

    return this.http.request(uploadRequest);
  }

  // download Template
  getTemplate(ftyp): Observable<any> {
    return this.http.get('./../../../assets/templates/' + ftyp + '.csv', {responseType: 'blob'});
  }

}
