import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { AuthService } from './shared/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  title = 'demo1';

  showSidebar: boolean = true;
  showNavbar: boolean = true;
  showFooter: boolean = true;
  isLoading: boolean;
  warned: boolean;
  timeout: number = 300;

  constructor(private router: Router, private bnIdle: BnNgIdleService, private auth: AuthService) {
    
    // Removing Sidebar, Navbar, Footer for Documentation, Error and Auth pages
    router.events.forEach((event) => { 
      // console.log(event['url']);
      
      if(event instanceof NavigationStart) {
        if((event['url'].includes('/login')) || (event['url'] == '/') || (event['url'] == '/resetPassword')  || (event['url'].includes('/resetPassword/changePassword')) || (event['url'] == '/error-pages/404') ) {
          this.showSidebar = false;
          this.showNavbar = false;
          this.showFooter = false;
          document.querySelector('.main-panel').classList.add('w-100');
          document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
          document.querySelector('.content-wrapper').classList.remove('auth', 'auth-img-bg', );
          document.querySelector('.content-wrapper').classList.remove('auth', 'lock-full-bg');
          if((event['url'] == '/error-pages/404')) {
            document.querySelector('.content-wrapper').classList.add('p-0');
          }
        } 
        else {
          this.showSidebar = true;
          this.showNavbar = true;
          this.showFooter = true;
          document.querySelector('.main-panel').classList.remove('w-100');
          document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
          document.querySelector('.content-wrapper').classList.remove('auth', 'auth-img-bg');
          document.querySelector('.content-wrapper').classList.remove('p-0');
        }
      }
    });

    // Spinner for lazyload modules
    router.events.forEach((event) => { 
      if (event instanceof RouteConfigLoadStart) {
          this.isLoading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
          this.isLoading = false;
      }
    });


    if (this.auth.isLoggedIn) {
      this.bnIdle.startWatching(this.timeout).subscribe((res) => {
        if (res) {
          if (!this.warned) {
            console.log("show warning");
            // $('#timeoutWarning').modal('show');
            this.warned = true;
            this.timeout = 30;
            this.bnIdle.stopTimer();
            this.bnIdle.startWatching(this.timeout);
          }
          else if (this.warned) {
            this.bnIdle.stopTimer();
            // $('#timeoutWarning').modal('hide');
            this.auth.doLogout();
          }
        }
      });
    }

  }

  ngOnInit() {
    // Scroll to top after route change
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
    });
  }

  resetWarning(){
    this.warned = false;
    this.timeout = 300;
    this.bnIdle.stopTimer();
    this.bnIdle.startWatching(this.timeout);
  }
}
