import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AccountingService } from 'src/app/shared/services/accounting.service';
import { AuditsService } from 'src/app/shared/services/audits.service';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-report-bill-payment',
  templateUrl: './report-bill-payment.component.html',
  styleUrls: ['./report-bill-payment.component.scss']
})
export class ReportBillPaymentComponent implements OnInit {

  loading: boolean;
  dateRange: string;
  report: any;

  loading2: boolean;
  params: string;

  config: any;
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  totalItems = 0;
  showin = 0;

  constructor(
    private accountingService: AccountingService,
    private toast: ToastrService,
    private auditService: AuditsService,
    public permService: PermissionsService
  ) { }

  ngOnInit(): void {
  }

  handleDuration(){
    if(!$('#startDate').val()){
      $('#startDate').css({'border-color':'red'});
    }
    else if(!$('#endDate').val()){
      $('#endDate').css({'border-color':'red'});
    }
    else{
      $('#startDate').css({'border-color':'#ced4da'});
      $('#endDate').css({'border-color':'#ced4da'});

      var duran = {
        startDate: $('#startDate').val(),
        endDate: $('#endDate').val()
      }

      this.dateRange = $.param(duran);

      this.loading = true;
      // console.log(this.params);
      this.getReport();
      
    }
  }

  handlePageChange(event) {
    this.page = event;
    this.getReport();
  }

  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getReport();
  }

  getReport(){
    this.accountingService.getPaginatedDateRangeT24Report(this.page - 1, 500, this.dateRange)
      .subscribe(
        data => {
          console.log(data);
          // this.config = {
          //   itemsPerPage: this.pageSize,
          //   currentPage: data.pageable.pageNumber + 1,
          //   totalItems: data.totalElements,
          //   totalPages: data.totalPages
          // };   
  
          // if(this.page * this.pageSize > data.totalElements){
          //   this.showin = data.totalElements;
          // }
          // else{
          //   this.showin = this.page * this.pageSize;
          // }
          
          this.report = data.content;
          this.loading = false;
        },
        err => {
          console.log(err);
          if(err.status == 0){
            this.toast.error('Failed to Connect.');
          }
          else{
            this.toast.error('Failed to get Report: ' + err.status);
          }
          this.loading = false;
        }
      );
  }

  exportReport(typ){
    if(!$('#startDate').val()){
      $('#startDate').css({'border-color':'red'});
      return false;
    }
    else if(!$('#endDate').val()){
      $('#endDate').css({'border-color':'red'});
      return false;
    }
    else{
      $('#startDate').css({'border-color':'#ced4da'});
      $('#endDate').css({'border-color':'#ced4da'});

      var duran = {
        reportFormat: typ,
        startDate: $('#startDate').val(),
        endDate: $('#endDate').val()
      }

      this.params = $.param(duran);
    }

    this.loading2 = true;
    this.auditService.exportEValueReport(this.params)
    .subscribe(
      resp => {
        this.loading2 = false;
        fileSaver.saveAs(resp, 't24 report from ' + duran.startDate + ' to ' + duran.endDate + '.' + typ.toLowerCase());
        // const url = window.URL.createObjectURL(resp);
			  // window.open(url);
      },
      err => {
        console.log(err);
        // this.toast.error('An Error Occured. Please Try Again.!');
        this.loading2 = false;
      }
    )
  }

}
